<template>
	
	<div class="accordion" role="tablist">
		
		<b-row>
			<b-col lg="6" md="6" sm="6" class="heading-title">
				<h4>Booking Logs	</h4>
				<b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
					<b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
					<b-breadcrumb-item to="/statements">Manage Booking</b-breadcrumb-item>
					<b-breadcrumb-item active>View Booking Logs</b-breadcrumb-item>
				</b-breadcrumb>
			</b-col>
			<b-col lg="6" md="6" sm="6" class="btns-header"> 
			
			</b-col>
		</b-row>
		<b-row>
			<b-col lg="12" md="12" sm="12" >
				
				<div class="row " >
					<div class="col-md-9 text-center mt-3"> 
						<b-card no-body class="mb-3 p-4">
							<h3><u style="text-decoration:none" class="border-b">Manage Booking</u> 
							<span class="animateCss" v-if="animateStart=='start_box'">  {{ other_logs.event_days_remaining-other_logs.event_days_completed ==0 ? 'Today' : other_logs.event_days_remaining-other_logs.event_days_completed > 0 ?  other_logs.event_days_remaining-other_logs.event_days_completed+' Remaining days' : ' '  }}</span></h3>
							<div class="progress pink mt-3" v-if="other_logs.status!=2">
								<div class="progress-bars"  :style="'width: '+percentage+'%; background: -webkit-linear-gradient(right, #89E539 '+remender_per+'%, red '+event_per+'%);    background: -o-linear-gradient(right, #89E539 '+percentage+' %, red '+event_per+'%);    background: -moz-linear-gradient(right, #89E539 '+percentage+'%, red '+event_per+'%);    background: linear-gradient(to right, #89E539 '+percentage+'%, red '+event_per+'%);;'" >
									<!--  background: linear-gradient(to right, #89E539 0%, #89E539 '+remender_per+'%, red '+event_per+'%, red 100%); -->
									<!-- style="width: 70%;  background-color:#89E539;" + 'px;left: '+coord.x + 'px' -->
								</div>
								<div class="border-l-5 h-10 "><!-- 
								<div id="dialog2" class="triangle_down1"></div> -->
								
								
								<div class="m-top-31">
									Booked on  {{ date_format(other_logs.booked_on) }}
									
								</div>
								
								
								
								</div>
								
								
								
								<div class="border-l-5 h-10 " :style="{'left' : remender_per + '%'}" >
									
									<div class="m-top-30">
										Reminder  {{ date_format(other_logs.remendar_date) }}
										
									</div>
								</div>
								<div class="border-l-5 h-10 " :style="{'left' : event_per + '%'}">
									
									<div class="m-top-32">
										Event on  {{ date_format(other_logs.from_date) }}
										
									</div>
								</div>
								
								<div class="border-l-5 h-10 " style="left:100%">
									
									<div class="m-top-33">
										Closed 
										
									</div>
								</div>
								
							</div>
							<div v-else class=" mt-3 text-danger">
								<center>Cancelled Booking</center>
							</div>
						</b-card>
						
					</div>
					<div class="col-md-3 text-center mt-3 "> 
						<b-card no-body class="mb-3 p-3 " :class="{[animateStart=='start_box' ? 'event_color' :'status_color']:true}">
							<h4>#Booking Ref No :  {{ other_logs.booking_auto_id }}</h4>
							<div class="d-flex justify-content-center mt-3">
								<h4>Status :  <b-badge variant="success" pill v-if="other_logs.status!=2">
									{{ animateStart=='start_box' ? "Event " : animateStart=='0' ? "Open" :  animateStart=='Closing' ? "Closing": animateStart=='Closed' ?  'Closed' :'' }}	
								</b-badge>
								<b-badge variant="danger" pill v-else>Cancelled</b-badge>
								</h4>
							</div>
							
						</b-card>
					</div>
				</div>
			</b-col>
			<b-col lg="6" md="6" sm="6" >
				<b-card class="mb-3" :class="{[animateStart]:true}">
					
					<table class="table table-borderless ">
						<tr>
							<th colspan="3" > <b class="border-b_">Event Details</b></th>
						</tr>
						<tr>
							<td class="text-left"> Venue name</td>
							<td>:</td>
							<td class="text-left">{{ other_logs.child_venue_name }} </td>
							
							
							<td class="text-left"> Event date</td>
							<td>:</td>
							<td class="text-left">{{ date_format(other_logs.from_date) }} </td>
							
							
						</tr>
						<tr>
							<td class="text-left">Event Name</td>
							<td>:</td>
							<td class="text-left">{{ other_logs.event_name }} </td>
							
							<td class="text-left"> Shift</td>
							<td>:</td>
							<td class="text-left">{{ this.check_shift(other_logs.shift_types_selected) }} </td>
							
						</tr> 
						
						<tr>
							
							
							<td class="text-left"> From Time</td>
							<td>:</td>
							<td class="text-left">{{ this.convert_24_12hour(other_logs.from_time) }}  </td>
							
							<td class="text-left"> To Time</td>
							<td>:</td>
							<td class="text-left">{{ this.convert_24_12hour(other_logs.to_time) }} </td>
						</tr>
					</table>
				</b-card>
				<b-card class="mb-3" :class="{[animateStart]:true}">
					<table class="table table-borderless ">
						<tr>
							<th colspan="3" > <b class="border-b_">Customer Details</b></th>
						</tr>
						
						<tr v-if=" other_logs.booking_for_self!=1" style="background-color: whitesmoke;">
							<td class="text-left">  Name & Relationship</td>
							<td>:</td>
							<td class="text-left">{{  other_logs.booking_for_name }}  &  {{  other_logs.booking_for_relationship }}</td>
							
							<td class="text-left"> Phone</td>
							<td>:</td>
							<td class="text-left">{{ other_logs.booking_for_mobile_no }} </td>
						</tr> 
						
						<tr>
							<td class="text-left"> Customer Name</td>
							<td>:</td>
							<td class="text-left"> {{  other_logs.billing_first_name }} </td>
							
							<td class="text-left"> Email</td>
							<td>:</td>
							<td class="text-left">{{ other_logs.billing_email }} </td>
						</tr> 
						
						<tr>
							<td class="text-left"> Phone</td>
							<td>:</td>
							<td class="text-left">{{ other_logs.billing_phone }} </td>
							
							<td class="text-left">Address</td>
							<td>:</td>
							<td class="text-left">{{ other_logs.billing_address }} </td>
						</tr> 
						
						
						
						
					</table>
				</b-card>
				
				<b-row>
					<b-col lg="6" md="6" sm="6" >
						<b-card  class="mb-3"  :class="{[animateStart]:true}">
							<template #header>
								<div class="d-flex justify-content-between">
									<div><h4 class="mb-0 border-b_"> Add-ons</h4></div>
									
								</div>
								
							</template>
							<div class="anyClass"  style="height: 347px;">
								<table class="table">
									<tr>
										<th class="text-left"><input type="checkbox"></th>
										<th class="text-left">  Add-On </th>
										<th class="text-left">Details</th>
									</tr>
									<tr v-for="(addon,i) in other_logs.addons" :key="i">
										<b-tooltip :target="'tooltip-targets-'+i" triggers="hover" placement="top" no-fade>
											<div class="p-3 border-b-2">
<div style="border-bottom:4px dotted #A44BF3">
	{{ addon.add_on_name }} Comment
</div>
											
											
											
											
											<b-list-group v-if="addon.comment" >
												<b-list-group-item class="d-flex justify-content-start list-group-items align-items-center"  v-for="(com,c) in JSON.parse(addon.comment)" :key="c">
													<img src="https://apitest.venuebook.in/Upload/Profile/user-svgrepo-com.svg" class="w-25">
													&nbsp;  <br> {{com.comment}}
												</b-list-group-item>
											</b-list-group>
											<b-input-group size="sm" class="mb-1 mt-3">
												<b-form-input class="border-b-2" v-model="remark"></b-form-input>
												<b-input-group-append>
													<b-button size="sm" text="Button" variant="success" @click="add_comment_creates(addon.add_on_name,addon.add_on_id,'Addon')"><i class="mdi mdi-send"></i></b-button>
												</b-input-group-append>
											</b-input-group>
										</div>
										</b-tooltip>
										
										<td class="text-left table-top-set" ><input type="checkbox" :checked="addon.status=='yes' ? 'checked' :'' "  :value="addon.add_on_id" @change="update_addon($event,addon.add_on_id,addon.add_on_name)"></td>
										<td class="text-left table-top-set"> <span :class="{[addon.status=='yes' ? 'text-success' : 'text-default' ]:true}" >{{ addon.add_on_name }}</span> 
											
											<!-- <span v-if="addon.comment!=''" class="mdi mdi-comment cursor-pointer" :id="'tooltip-targets-'+i"></span> -->
											&nbsp; 
											<span v-if="addon.comment" class="mdi mdi-comment cursor-pointer text-success"  :id="'tooltip-targets-'+i"></span> <!-- v-if="task.comments" -->
											<span v-else  class="mdi mdi-comment cursor-pointer" :id="'tooltip-targets-'+i"></span> <!-- v-if="task.comments" -->
											
										</td>
										<!--  <td> <b-badge variant="primary" pill>{{ addon.qty }}</b-badge></td> -->
										<td class="text-left w-40" :class="{[addon.status=='yes' ? 'text-success' : 'text-default' ]:true}" >
											Price : <span v-html="currency_check(formatPrice(addon.price ))"></span><br>
											Quantity :  {{ addon.qty }}<br>
											Total : <span v-html="currency_check(formatPrice(addon.total ))"></span>
										</td>
										
									</tr>
									
								</table>
							</div>
						</b-card>
					</b-col>
					<b-col lg="6" md="6" sm="6" >
						<b-card  class="mb-3 "  :class="{[animateStart]:true}">
							<template #header>
								<div class="d-flex justify-content-between">
									<div><h4 class="mb-0 border-b_"> Task & Reminder </h4></div>
									<div><button :disabled="event_remain_day_check < -2 || other_logs.status==2"  class="btn btn-venuecolor btn-block  btn-sm" 
									v-b-modal="'modal-add-task'"><i class="mdi mdi-plus"></i> Task</button></div>
									
								</div>
								
							</template>
							
							<!-- <img  v-if="tasks.length <= 0" src="https://cdni.iconscout.com/illustration/premium/thumb/rejecting-extra-task-10367620-8372691.png" style="width:273px;height: 340px;">
							-->
							<div class="anyClass"  style="height: 347px;">
								<table class="table">
									<tr>
										<th class="text-left">#</th>
										<th class="text-left">  Task name </th>
										<th class="text-left"></th>
									</tr>
									<tr v-for="(task,ta) in other_logs.task_all" :key="ta">
										<td><input type="checkbox" :checked="task.task_status=='yes' ? 'checked' :'' "  :value="task.task_name" @change="enable_taks_check($event,task.task_name,task.task_status)"></td>
										<td>
											<span :class="{[task.task_status=='yes' ? 'text-success' : 'text-default' ]:true}" >{{task.task_name}}</span>
											
											
											<b-tooltip :target="'tooltip-target-'+ta" triggers="hover" placement="top" no-fade>
												<div class="p-3 border-b-2">
<div style="border-bottom:4px dotted #A44BF3">
	{{ task.task_name }} Comment
</div>
												<b-list-group v-if="task.task_details.length>0">
													<b-list-group-item class="d-flex justify-content-start list-group-items align-items-center" v-for="(com,c) in task.task_details" :key="c">
														<img :src="com.logo ? com.logo : 'https://apitest.venuebook.in/Upload/Profile/user-svgrepo-com.svg'" class="w-25">
														{{ com.username }}
														&nbsp;  <br> {{com.comment}}<small>{{ com.ago }}</small>
													</b-list-group-item>
												</b-list-group>
												
												<b-input-group size="sm" class="mb-1 mt-3">
													<b-form-input class="border-b-2" v-model="remark"></b-form-input>
													<b-input-group-append>
														<b-button size="sm" text="Button" variant="success" @click="add_comment_creates(task.task_name,'','Task')"><i class="mdi mdi-send "></i></b-button>
													</b-input-group-append>
												</b-input-group>
												</div>
											</b-tooltip>
										
										</td>
										<td class="text-right">
											<i v-if="task.task_details.length>0" class="mdi mdi-comment cursor-pointer text-success "  :id="'tooltip-target-'+ta"></i> <!-- v-if="task.comments" -->
											<i v-else  class="mdi mdi-comment cursor-pointer " :id="'tooltip-target-'+ta"></i> <!-- v-if="task.comments" -->
											<i class="mdi mdi-bell ml-5 cursor-pointer" :class="{[task.remainder_date!=null ? 'bell' :'no-bell']:true}" @click="update_task($event,task.task_name,task.task_status,task)" ></i>
											<!--v-if="task.remainder_date!=null"-->
										</td>
									</tr>
								</table>
							</div>
							
							<b-modal id="modal-1" :title="task.name" 
							centered ok-title="Save Remainder" 
							@ok="update_taks_remainder()" 
							header-bg-variant="primary" 
							header-text-variant="light">
								
								<form ref="form" @submit.stop.prevent="handleSubmit">
									<b-row>
										<b-col lg="5" md="5" sm="5" :class="{'center_task':true,[task.check == 'no' ? '' : 'disable_task']:true}">
											<b-form-group
											label="Remainder date"
											label-for="name-input"
											invalid-feedback="Name is required"
											
											>
												<b-form-datepicker
												id="start_booking_date"
												placeholder="Remainder  date"
												class="mb-2"
												v-model="task.remainder_date"
												:min="other_logs.booked_on"
												:max="other_logs.from_date"
												:date-format-options="{
												year: 'numeric',
												month: 'short',
												day: '2-digit',
												}"
												></b-form-datepicker>
											</b-form-group>
											
											<input type="time" v-model="task.time_before" class="form-control">
											
											<b-form-checkbox v-model="task.repeat_before_checked" name="check-button" class="mt-2" switch>
												Repeat Remainder
											</b-form-checkbox>
											
											<b-form-select v-if="task.repeat_before_checked"
											v-model="task.repeat_remainder"
											:options="options"
											class="mb-3 mt-3"
											value-field="item"
											text-field="name"
											
											></b-form-select>
											
											<b-form-checkbox v-model="task.sound_before_checked"  class="mt-2" name="check-button" switch>
												Notification Sound
												
											</b-form-checkbox>
										</b-col>
										<b-col lg="1" md="1" sm="1" style="border-right: 1px solid red;" class="text-center">
										</b-col>
										<b-col lg="5" md="5" sm="5" :class="{'center_task':true,[task.check == 'no' ? 'disable_task' : '' ]:true}">
											<b-form-group
											label="Remainder date"
											label-for="name-input"
											invalid-feedback="Name is required"
											
											>
												<b-form-datepicker
												id="start_booking_date_"
												placeholder="Remainder date"
												class="mb-2"
												v-model="task.after_remainder_date"
												:min="task.remainder_date"
												:max="other_logs.from_date"
												:date-format-options="{
												year: 'numeric',
												month: 'short',
												day: '2-digit',
												}"
												></b-form-datepicker>
											</b-form-group>
											
											<input type="time" v-model="task.time_after" class="form-control">
											
											<b-form-checkbox v-model="task.repeat_after_checked"  class="mt-2" name="check-button" switch>
												Repeat Remainder
												
											</b-form-checkbox>
											
											<b-form-select v-if="task.repeat_after_checked"
											v-model="task.repeat_remainder1"
											:options="options"
											class="mb-3 mt-3"
											value-field="item"
											text-field="name"
											></b-form-select>
											
											<b-form-checkbox v-model="task.sound_after_checked"  class="mt-2" name="check-button" switch>
												Notification Sound
												
											</b-form-checkbox>
										</b-col>
									</b-row>
								</form>	
								<hr>	
								
							</b-modal>
							<!--    {{other_logs.tasks}}
							<b-form-checkbox v-model="event.add_ready" v-for="(task,tas) in other_logs.tasks" :key="tas">{{ task.task_name }}</b-form-checkbox>  -->
							
						</b-card>
					</b-col>
					
				</b-row>
				
				<b-card class="mb-1">
					<template #header>
						<div class="d-flex justify-content-between">
							<div><h4 class="mb-0 border-b_"> Remark</h4></div>
							<div><button v-if="!editTrue"  class="btn btn-venuecolor btn-block  btn-sm"  @click="editRemark"
								><i class="mdi mdi-pencil" ></i> Edit  </button>
								
								<button  :disabled="event_remain_day_check<-2"  v-else class="btn btn-venuecolor  btn-block  btn-sm"   @click="saveRemark"
								><i class="mdi mdi-content-save"></i> Save </button>
							</div>
						</div>
						
					</template>
					<!-- <b-button class="btn-venuecolor btn-sm mb-2 " :disabled="cliked=='Task' || cliked==''" @click="add_comment_create">AddOn Comment</b-button>  &nbsp; 
						<b-button class="btn-venuecolor  btn-sm mb-2" :disabled="cliked=='Addon' || cliked==''" @click="add_comment_create">Reminder Comment</b-button> &nbsp; 
					<b-button class="btn-venuecolor  btn-sm mb-2" @click="newtask">New Task</b-button> -->
					<div style="height: 290px; " class="anyClass">
						<vue-editor v-if="editTrue" v-model="remarks" class="venue-custom-input" :editorToolbar="customToolbar"></vue-editor>
						<div class="" v-else> 
							<span v-html="remarks" v-if="remarks"></span>
							
							<img v-else :src="require('@/assets/images/nodatafound.jpg')">
						</div>
						
					</div>
					<!-- 
					<textarea class="form-control" rows="12" :placeholder="'Enter '+label_name+' Comment'" v-model="remark"></textarea> -->
				</b-card>
			</b-col>
			
			<b-col lg="6" md="6" sm="6" >
				<b-row>
					<b-col lg="6" md="6" sm="6" >
						<b-card class="mb-1" :class="{[animateStart]:true}">
							<template #header>
								<div class="d-flex justify-content-between">
									<div><h4 class="mb-0 "> <div class="border-b_">Payment Information</div></h4></div>
								</div>
							</template>
							<table class="table table-borderless ">
								<tr>
									<td class="text-left"> Base Price </td> <td class="text-right"> <span v-html="currency_check(formatPrice(Math.round(other_logs.total_booking_price) ))"></span></td>
								</tr>
								<tr>
									<td class="text-left">Addon Total: </td> <td class="text-right"> <span v-html="currency_check(formatPrice(Math.round(other_logs.add_on_total) ))"></span></td>
								</tr>
								<tr>
									<td class="text-left"> Discount <span v-if="discount_percentage!=0">({{ discount_percentage }} %)</span>: </td> <td class="text-right"> <span v-html="currency_check(formatPrice(Math.round(other_logs.applied_discount) ))"></span></td>
								</tr>
								<tr>
									<td class="text-left">Subtotal : </td> <td class="text-right"> <span v-html="currency_check(formatPrice(Math.round(other_logs.sub_total) ))"></span> </td>
								</tr>
								<tr>
									<td class="text-left">  GST ({{ other_logs.tax_percentage }}%): </td> <td class="text-right"> <span v-html="currency_check(formatPrice(Math.round(other_logs.gst_amount) ))"></span> </td>
								</tr> 
								<tr>
									<td class="text-left">  Total </td> <td class="text-right"> <span v-html="currency_check(formatPrice(Math.round(other_logs.full_total) ))"></span> </td>
								</tr>
								<tr>
									<td colspan="2"><hr class="border-b"></td>
								</tr>
								<tr>
									<td class="text-left">  Security Deposit : </td> <td class="text-right">
										<span v-html="currency_check(formatPrice(Math.round(other_logs.security_deposit) ))"></span>
										
									</td>
								</tr>
							</table>
						</b-card>
						
						<b-list-group>
							
							
							<b-card class="mb-3 mt-3 " >
								<template #header>
									<div class="d-flex justify-content-between">
										<div><h4 class="mb-0 border-b_">  Receipt</h4></div>
										<div><button   class="btn btn-venuecolor btn-block  btn-sm" 
											:disabled="form.balance.Total_amount<=0"
										v-b-modal="'modal-booing-price'"><i class="mdi mdi-plus"></i> Receipt</button></div>
									</div>
									
								</template>
								<!-- v-if="this.after_paid > 0" -->
								<div class="anyClass" style="height:350px">
									<table class="table table-recipt">
										<thead>
											<tr>
												<th class="text-left">Details</th>
												<th class="text-right">Amount</th>
											</tr>
										</thead>
										<tbody v-if="other_logs.recept_booking.length>0">
										<tr v-for="(vb,v) in other_logs.recept_booking" :key="v">
											<td class="text-left"> {{ date_format(vb.paid_date) }} <br>
												<span class="mdi mdi-printer cursor-pointer fs-20" @click="link_check(vb.bookings_price_id)"></span>
												&nbsp; {{ vb.receipt_type==1 ? 'Base Price' : vb.receipt_type==2 ? "AddOn " :"Security Deposit" }}
											</td>
											
											<td class="text-right"><span v-html="currency_check(formatPrice(Math.round(vb.price) ))"></span></td>
											
										</tr>
										<tr>
											<td class="text-left">Paid Total</td>
											<td class="text-right"><span v-html="currency_check(formatPrice(calculateSum()))"></span></td>
											
										</tr>
										</tbody>
										<tbody v-else>
											<tr style="height:250px">
												<td colspan="2">
													<img :src="require('@/assets/images/nodatafound.jpg')" class="w-100">
												</td>
											</tr>
										</tbody>
										<tfoot>
											<tr>
												<td class="text-left">
													Balance Amount
												</td>
												<td class="text-right">
													<span v-if="this.form.balance.Total_amount>=0">
														<span v-html="currency_check(formatPrice(Math.round(this.form.balance.Total_amount) ))"></span>
														
													</span>
													<span v-else class="text-danger">
														(<span v-html="currency_check(formatPrice(-(Math.round(this.form.balance.Total_amount+this.booking_form_details.refund_paid)) ))"></span>)
														
													</span>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>
								
								
								
							</b-card>
							<b-card style="height:285px" >
							
							<template #header>
								<div class="d-flex justify-content-between">
									<div><h4 class="mb-0 border-b_"> Special Request</h4></div>
									
								</div>
								
							</template>
							<span v-html="other_logs.special_request" v-if="other_logs.special_request"></span>
							<img v-else :src="require('@/assets/images/nodatafound.jpg')" class="w-100">
							
							
							
							
							
							
							
						</b-card>
							
							
						</b-list-group>
					</b-col>
					<b-col lg="6"  md="6" sm="6" >
						
						<div class=" side-container"  ref="side"
    :class="{ 'side-container--sticky': isHeaderSticky }">
						<b-card class="mb-3">
							<b-row class="mb-3">
								<b-col lg="4" md="4" sm="4" :class="{[other_logs.status!=2 ? '' : 'disable_button']:true}">
									<router-link :to="'/edit-booking/' + other_logs.booking_id+'/Edit'">
										<b-button class="btn-success btn-block" :disabled="permission_access.vb_edit==0 ||  event_remain_day_check < 0 || other_logs.invoice_date!=null ">Edit /Add Booking</b-button>
									</router-link>
									
								</b-col>
								<b-col lg="4" md="4" sm="4" >
									<router-link :to="'/my-booking/invoice/' + other_logs.booking_id" >
										<b-button class="btn-venuecolor btn-block" :disabled="other_logs.invoice_date!=null">Proforma Invoice</b-button>
									</router-link>
									
								</b-col>
								<b-col lg="4" md="4" sm="4" :class="{[other_logs.status!=2 ? '' : 'disable_button']:true}" >
									
									<span v-if="other_logs.booking_base_prce!=0 && other_logs.booking_addon_prce!=0  && other_logs.booking_security_prce!=0">
										<span v-if="other_logs.invoice_date==null">
											
											<b-button class="btn-info btn-block"  :disabled="event_remain_day_check > 0"  @click="generate_invoice(other_logs.booking_id)" >
												Invoice Generate
											</b-button>
										</span>
										<span v-else>
											<router-link :to="'/finance/invoice/' + other_logs.booking_id">
												<b-button class="btn-info btn-block"  :disabled="event_remain_day_check > 0" >
													View <br>Invoice
												</b-button>
											</router-link>
										</span>
										
										
									</span>
									<span v-else>
										
										<b-button class="btn-default btn-block"  disabled >
											Generate Invoice  
										</b-button>
									</span>
									
									
								</b-col>
							</b-row>
							<b-row class="mb-3" >
								<b-col lg="4" md="4" sm="4" :class="{[other_logs.status!=2 ? '' : 'disable_button']:true}">
									<!-- <b-button class="btn-venuecolor btn-block"  :disabled="permission_access.vb_edit==0 ||  event_remain_day_check < -2 ">Remove</b-button> -->
									<router-link :to="'/edit-booking/' + other_logs.booking_id+'/Remove'">
										<b-button class="btn-danger btn-block" :disabled="permission_access.vb_edit==0 ||  event_remain_day_check < 0 || other_logs.invoice_date!=null">Remove Add On</b-button>
									</router-link>
									
								</b-col>
								<b-col lg="4" md="4" sm="4" v-if="other_logs.status!=2">
									
									<b-button class="btn-warning btn-block"  :disabled="event_remain_day_check > 0 || other_logs.invoice_date!=null || this.form.balance.Total_amount > 0" @click="Refund_payment">Refund Payment</b-button> 
									<!-- <b-button class="btn-warning btn-block"  @click="Refund_payment">Refund Payment</b-button> -->
									
								</b-col>
								<b-col lg="4" md="4" sm="4" v-else>
									
									<b-button class="btn-warning btn-block"   @click="Refund_payment" :disabled=" other_logs.invoice_date!=null || this.form.balance.Total_amount > 0">Cancel Payment</b-button>
									
								</b-col>
								<b-col lg="4" md="4" sm="4" v-if="allowcancel==1" :class="{[other_logs.status!=2 ? '' : 'disable_button']:true}">
									<b-button class="btn-danger btn-block" :disabled="event_remain_day_check < 0 || other_logs.invoice_date!=null " @click="cancel_booking">Cancel Booking</b-button>
								</b-col>
								<b-col lg="4" md="4" sm="4" v-else :class="{[other_logs.status!=2 ? '' : 'disable_button']:true}">
									<b-button class="btn-default btn-block " disabled >Cancel Booking</b-button>
								</b-col>
								
								<!--	<b-col lg="4" md="4" sm="4">
									
									<b-button class="btn-warning btn-block"   @click="Refund_payment">Test Refund Payment</b-button>
									
									</b-col>
									
									<b-col lg="4" md="4" sm="4">
									
									<b-button class="btn-info"  @click="generate_invoice(other_logs.booking_id)" >
									Test Invoice Generate
									</b-button>
									
								</b-col> -->
							</b-row>
						</b-card>
						
						
						
						<b-card class="mb-3 " >
							<template #header>
								<div class="d-flex justify-content-between">
									<div><h4 class="mb-0 border-b_"> Logs</h4></div>
									
								</div>
								
							</template>
							<b-list-group style="height:380px" class="anyClass">
								<div v-for="(log1,ii) in new_booking_logs" :key="ii">
									<b-list-group-item href="#" class="flex-column align-items-start"  v-if="log1.message">
										
										<div class="d-flex w-100 justify-content-between" v-if="log1.message">
											<h6 class="mb-1"><i class="text-venuecolor">{{ log1.username }}</i> - {{ log1.log }}</h6>
											
										</div>
										{{log1.message}}
										<small class="text-muted" v-if="log1.message">{{log1.ago}}</small>
										
									</b-list-group-item>
									
								</div>
							</b-list-group>
						</b-card>
						
					</div>
					</b-col>
					
				</b-row>
				
			</b-col>
			
		</b-row>
		
		
		
		
		<!-- Security Deposit -->
		<b-modal
		id="modal-add-task" title="Add New TAsk" 
		header-bg-variant="primary"
		header-text-variant="light"
		:no-close-on-backdrop="true"
		size="md"
		:hide-footer="true"
		:hide-header="false"
		:centered="true"
        ref="modal-add-task"
		> 
			<form ref="form" >
				<b-row>
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="New Task">
							<b-input-group >
								<b-form-input
								v-model="newtask_name"
								type="text"
								placeholder="Add New Task name"
								></b-form-input>
								
							</b-input-group>
						</b-form-group>
						
					</b-col>
				</b-row>
				
			</form>
			<div class="d-flex mt-3 float-right" > 
				<b-button variant="primary"  @click="newtask()" :disabled="newtask_name==''">Save</b-button
				> 
				
				<b-button variant="outline-secondary" @click="closeTask()" class="ml-2"
				>Close</b-button
				>
			</div>
		</b-modal>
		
		
		<b-modal
		id="parent_child_venue1" title="Refund Payment" 
		header-bg-variant="primary"
		header-text-variant="light"
		:no-close-on-backdrop="true"
		size="md"
		:hide-footer="true"
		:hide-header="false"
		:centered="true"
        ref="parent_child_venue1"
		> 
			<form ref="form" @submit.stop.prevent="savePrice">
				<b-row>
					
					<b-col lg="3" md="3" sm="12">
					</b-col>
					<b-col lg="7" md="7" sm="12">
						<!-- <span v-if="refunded_amount==0">
							Security Deposit Amount : <span v-html="currency_check(formatPrice(total_security_amount.paid_security))"></span>
							</span>
							<span v-if="refunded_amount!=0">
							Deducted Amount : <span v-html="currency_check(formatPrice(refunded_amount))"></span>
						</span> -->
						
						Amount : <span v-html="currency_check(formatPrice(total_security_amount.paid_security))"></span>
						
						
					</b-col>
					<b-col lg="12" md="12" sm="12">
						<hr>
					</b-col>
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Date of Refund">
							<b-form-datepicker
							v-model="date_refund"
							id="payment_date"
							placeholder="Start date"
							class="mb-2"
							:min="today_date"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</b-form-group>
					</b-col>
					
					<b-col lg="6" md="6" sm="12">
						<b-form-group label="Deduct Amount">
							<b-input-group >
								<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
								
								
								<b-form-input
								v-model="refund_amt"
								type="number"
								placeholder="Enter price"
								@input="checkrefund_amount()"
								></b-form-input>
								
							</b-input-group>
							<span class="text-danger" v-if="refund_status"> check Refund amount </span>
							
						</b-form-group>
						
					</b-col>
					<b-col lg="12" md="12" sm="12">
						<b-form-group label="Reason ">
							<b-input-group >
								
								
								<b-form-textarea
								v-model="reason_for"
								type="text"
								@input="checkrefund_amount()"
								placeholder="Enter Reason for deduct"
								></b-form-textarea>
								
							</b-input-group>
							<span class="text-danger" v-if="reason_status">Deduct Reason Required  </span>
						</b-form-group>
						
					</b-col>
					
					<b-col lg="6" md="6" sm="12" v-if="tax_amount_view">
						
						
						<b-form-group label="Deduction Amount with tax ">
							<div v-html="currency_check(formatPrice(refund_amt_tax))"  class="text-right border-whitesmoke"  ></div>
						</b-form-group>
						
					</b-col>
					<b-col lg="6" md="6" sm="12" v-if="tax_amount_view">
						<b-form-group label=" Total Refund Amount ">
							<div v-html="currency_check(formatPrice(ref_amount))"  class="text-right border-whitesmoke" ></div>
						</b-form-group>
						
					</b-col> 
				</b-row>
			</form>
			<div class="d-flex mt-3 float-right" > 
				<b-button variant="primary" 
				v-if="!tax_amount_view" @click="taxCalculate()" :disabled="refund_status">Confirm</b-button
				> 
				<b-button variant="primary" :disabled="ref_amount < 0 || loader_appy || this.refund_amt < 0 || refund_status || reason_status"  v-if="tax_amount_view" @click="Save_refund_amount()"
				> <b-spinner small v-if="loader_appy" ></b-spinner>Refund</b-button
				>
				<b-button variant="outline-secondary" @click="closeReceipt()" class="ml-2"
				>Close</b-button
				>
			</div>
		</b-modal>
		<!-- Security Deposit -->
		
		
		
		<!-- rECEIPT -->
		<b-modal
		id="parent_child_venue"
		:no-close-on-backdrop="true"
		size="lg"
		:hide-footer="true"
		:hide-header="true"
		:centered="true"
        ref="parent_child_venue"
		>
			<div id="print_layout">
				<table style="width: 100%;">
					<tr>
						
						<td style="width: 50%;vertical-align: middle;text-align: left;" > 
							<img :src="logo ? logo : require('@/assets/images/venue_booking/logo.svg')" class="w-50  " alt="Booking logo" />
						</td>
						<td style="width: 50%; text-align: right;">
							<h1>Receipt</h1>
							<table style="color: #5c5757;float: right;text-align: right;width: 60%;">
								<tr>
									<td colspan="2" style="padding:0;text-align: right;color:red"><span  v-if="booking_details1.receipt_type==3" >
										(<span v-if="JSON.parse(this.booking_details1.all_settings).security_refund==true" >Refundable</span> Security Deposit)
									</span></td>
								</tr>
								
								<tr>
									<td style="padding:2px;text-align: left;" >Receipt No.</td> <td style="padding:2px">{{booking_details1.receipt_no}}</td>
								</tr>
								<tr>
									<td style="padding:2px;text-align: left;">Receipt date</td> <td style="padding:2px">{{booking_details1.paid_date}}</td>
								</tr>
								<tr >
									<td style="padding:2px;text-align: left;">Invoice No. </td> <td style="padding:2px">{{booking_details1.booking_auto_id}}</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
				<br>
				<table style="width: 100%;">
					<tr>
						<td style=" text-align: left;"> 
							<table style="float: left;text-align: left;" >
								<tr>
									<td style="padding:0;">From,</td> 
								</tr>
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.users.first_name }} {{ this.users.last_name }} </td> 
								</tr>
								
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.users.address }}</td> 
								</tr>
								
								<tr>
									<td style="padding:0;color: #5c5757;"> {{ this.users.phone }}</td> 
								</tr> 
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.users.email }}</td> 
								</tr>
								
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.users.gst_no }}</td> 
								</tr>
							</table>
						</td>
						<td style=" text-align: right;">
							<table style="margin-top: -26px;float: right;text-align: right;">
								<tr>
									<td style="padding:0;">Bill to,</td> 
								</tr>
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.booking_details1.billing_first_name }}</td>
								</tr>
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.booking_details1.billing_address }}</td> 
								</tr>
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.booking_details1.billing_phone }} </td> 
								</tr>
								<tr>
									<td style="padding:0;color: #5c5757;">{{ this.booking_details1.billing_email }}</td> 
								</tr>
							</table>
						</td>
					</tr>
				</table>
				<table style="width:100%;margin-top: 4%;" id="invoiceTable">
					<thead style="background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%); color: white;">
						<tr>
							<th>Sl. No.</th>
							<th>Particulars</th>
							<th>Total	</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="this.booking_details1.receipt_type==1">
							<td style="width:15%">1</td>
							<td style="width: 40%;"><span style="font-weight:bold">{{ this.booking_details1.child_venue_name }}</span>
							<br><i style="font-size: 12px;color:#5c5757"> On {{  date_format(this.booking_details1.selected_date) }}, {{ checkShift(this.booking_details1.booked_shift_type) }} Shift  (From {{ convert_24_12hour(this.booking_details1.from_time) }} to {{convert_24_12hour(this.booking_details1.to_time)}}) </i></td>
							<td style="width: 25%; text-align: right;"><span v-html="currency_check(formatPrice(this.booking_details1.price ))"></span> <!-- Rs. {{ formatPrice(this.booking_details1.total_booking_price) }} --></td>
						</tr>
						<tr v-if="this.booking_details1.receipt_type==2">
							<td style="width:15%">1</td>
							<td style="width: 40%;"><span style="font-weight:bold">{{ this.booking_details1.child_venue_name }}</span>
								<br><i style="font-size: 12px;color:#5c5757"> On {{  date_format(this.booking_details1.selected_date) }}, {{ checkShift(this.booking_details1.booked_shift_type) }} Shift  (From {{ convert_24_12hour(this.booking_details1.from_time) }} to {{convert_24_12hour(this.booking_details1.to_time)}}) </i>
								<br><span style="font-weight:bold">Addon </span><br>
								<i style="font-size: 13px;color:#5c5757">{{ this.booking_details1.addon }} </i> 
								
							</td>
							<td style="width: 25%; text-align: right;"><span v-html="currency_check(formatPrice(this.booking_details1.price ))"></span> <!-- Rs. {{ formatPrice(this.booking_details1.total_booking_price) }} --></td>
						</tr>
						<tr v-if="this.booking_details1.receipt_type==3">
							<td style="width:15%">1</td>
							<td style="width: 40%;"><span style="font-weight:bold">{{ this.booking_details1.child_venue_name }}</span>
								<br><i style="font-size: 12px;color:#5c5757"> On {{  date_format(this.booking_details1.selected_date) }}, {{ checkShift(this.booking_details1.booked_shift_type) }} Shift  (From {{ convert_24_12hour(this.booking_details1.from_time) }} to {{convert_24_12hour(this.booking_details1.to_time)}}) </i>
							<br><span style="font-weight:bold"> Security Deposit </span></td>
							<td style="width: 25%; text-align: right;"><span v-html="currency_check(formatPrice(this.booking_details1.price ))"></span> <!-- Rs. {{ formatPrice(this.booking_details1.total_booking_price) }} --></td>
						</tr>
						
						<tr>
							<td colspan="2" style="font-weight:bold" v-if="this.booking_details1.price>0">{{number2text(this.booking_details1.price)}} </td>
							<td style="padding:2px;text-align: right;font-weight:bold"><span v-html="currency_check(formatPrice(this.booking_details1.price  ))"></span></td>
							
						</tr>
						
						<tr>
							<td colspan="3"></td>
						</tr>
						<tr>
							<td   colspan="3" >
								<div class="d-flex justify-content-between">
									<div>
										Payment Method:  {{this.booking_details1.payment_mode}}
									</div>
									<div  v-if="this.booking_details1.payment_mode!='cash'">
										<span v-if="this.booking_details1.payment_mode=='online'">
											<span v-if="this.booking_details1.payment_details"> Name : {{ JSON.parse(this.booking_details1.payment_details).Online_name }} </span>
											
										</span>
										<span v-else >
											<span v-if="this.booking_details1.payment_details">Cheque No. : {{JSON.parse(this.booking_details1.payment_details).check_no}} </span>
											
										</span>
									</div>
									
									<div class="mr-5" v-if="this.booking_details1.payment_mode!='cash'">
										<span v-if="this.booking_details1.payment_mode=='online'" >
											<span v-if="this.booking_details1.payment_details">Bank Name : {{JSON.parse(this.booking_details1.payment_details).bank_name}} </span>
										</span>
										<span v-else >
											<span v-if="this.booking_details1.payment_details">	Bank Name : {{JSON.parse(this.booking_details1.payment_details).Online_bank}} </span>
										</span>
									</div>
								</div>
							</td>
							
						</tr>
						<tr>
							
							<td colspan="3"></td>
						</tr>
					</tbody>
					<tbody style="background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%); color: white;">
						<tr>
							<td colspan="2" style="font-weight:bold">{{number2text(this.booking_details1.balance_amount)}} </td>
							<td  style="padding:2px;text-align: right;font-weight:bold">Balance Due  : <span v-html="currency_check(formatPrice(this.booking_details1.balance_amount ))"></span></td>
							
							
						</tr>
					</tbody>
					<tfoot style="height: 5em;">
						<tr>
							<td colspan ="2" class="text-center">
								<span  v-if="recipt_type==3">
									<span v-if="JSON.parse(this.booking_details1.all_settings).security_days"> Security Deposit can be claimed on surrender of this receipt after {{JSON.parse(this.booking_details1.all_settings).security_days }} days but within 30 days of the function</span>
								</span>
								<span  v-else>
									Thank you for your business
								</span>
							</td >
							<td class="text-right">Name & Sign
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
			<div class="d-flex mt-3 float-right">
				<b-button variant="outline-secondary" 
				@click="receipt_print_page()">Print</b-button
				>
				<b-button variant="outline-secondary" @click="closeReceipt()" class="ml-2"
				>Close</b-button
				>
			</div>
			
		</b-modal>
		<!-- rECEIPT -->
		
		<b-modal id="modal-booing-price" title="Receipt Payment"  ref='modal-booing-price'
		header-bg-variant="primary"
		header-text-variant="light"
		@ok="handleOk"
		:centered="true"
		ok-title="Add Receipt"
		:ok-disabled="payment_error_message || payment_error_message1 || payment_error_message2 ">
			<form ref="form" @submit.stop.prevent="savePrice">
				
				<table class="table borderless" v-if="this.booking_details.booking_type=='1'">
					<tr>
						<td>Receipt Type</td>
						<td  class="text-right" >Balance</td>
						<td  class="text-right">Price</td>
					</tr>
					
					<tr>
						<td> Base Price </td>
						<td class="text-right"><span v-html="currency_check(formatPrice(this.form.balance.base_price))"></span></td>
						
						<td style="    width: 38%;">
							<b-input-group >
								<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
								
								
								<b-form-input  class="text-right"
								v-model="form.price"
								type="text"
								placeholder="Enter price"
								:state="validateState('price')"
								@input="validate_amount(1)"
								></b-form-input>
								<b-form-invalid-feedback id="first_name-1-live-feedback">
									<span v-if="!this.$v.form.price.required">
										Please enter price.</span
									>
									<span
									v-if="this.$v.form.price.required && !this.$v.form.price.numeric"
									>Price must be in numeric.</span
									>
									<span
									v-if="
									this.$v.form.price.required &&
									this.$v.form.price.numeric &&
									!this.$v.form.price.price_greater
									"
									>Price must be greater than 0.</span
									>
								</b-form-invalid-feedback>
							</b-input-group>
							<div v-if="payment_error_message" class="text-danger">Please Check amount</div>
						</td>
					</tr>
					<tr>
						<td>Add-Ons</td>
						<td class="text-right"><span v-html="currency_check(formatPrice(this.form.balance.add_total))"></span></td>
						<td style="    width: 38%;">
							<b-input-group >
								<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
								
								
								<b-form-input  class="text-right"
								v-model="form.price1"
								type="text"
								placeholder="Enter price"
								:state="validateState('price1')"
								@input="validate_amount(2)"
								></b-form-input>
								<b-form-invalid-feedback id="first_name-1-live-feedback">
									<span v-if="!this.$v.form.price1.required">
										Please enter price.</span
									>
									<span
									v-if="this.$v.form.price1.required && !this.$v.form.price1.numeric"
									>Price must be in numeric.</span
									>
									<span
									v-if="
									this.$v.form.price1.required &&
									this.$v.form.price1.numeric &&
									!this.$v.form.price1.price_greater
									"
									>Price must be greater than 0.</span
									>
								</b-form-invalid-feedback>
							</b-input-group>
							<div v-if="payment_error_message1" class="text-danger">Please Check amount</div>
						</td>
					</tr>
					<tr>
						<td>Security Deposit</td>
						<td class="text-right"><span v-html="currency_check(formatPrice(this.form.balance.security_deposit))"></span></td>
						<td style="    width: 38%;">
							<b-input-group >
								<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
								
								
								<b-form-input class="text-right"
								v-model="form.price2"
								type="text"
								placeholder="Enter price"
								:state="validateState('price2')"
								@input="validate_amount(3)"
								></b-form-input>
								<b-form-invalid-feedback id="first_name-1-live-feedback">
									<span v-if="!this.$v.form.price2.required">
										Please enter price.</span
									>
									<span
									v-if="this.$v.form.price2.required && !this.$v.form.price2.numeric"
									>Price must be in numeric.</span
									>
									<span
									v-if="
									this.$v.form.price2.required &&
									this.$v.form.price2.numeric &&
									!this.$v.form.price2.price_greater
									"
									>Price must be greater than 0.</span
									>
								</b-form-invalid-feedback>
							</b-input-group>
							<div v-if="payment_error_message2" class="text-danger">Please Check amount</div>
						</td>
					</tr>
					<tr>
						<td>Total </td>
						<td class="text-right"><span v-html="currency_check(formatPrice(this.form.balance.Total_amount))"></span></td>
						<td class="text-right"><span v-html="currency_check(formatPrice(this.form.total_amounts))"></span></td>
					</tr>
				</table>
				
				<b-row>
					
					<b-col lg="6" md="6" sm="6">
						
						<b-form-group label="Date of Payment">
							<b-form-datepicker
							v-model="form.payment_date"
							id="payment_date"
							placeholder="Start date"
							class="mb-2"
							:min="today_date"
							:state="validateState('payment_date')"
							:date-format-options="{
							year: 'numeric',
							month: 'short',
							day: '2-digit',
							}"
							></b-form-datepicker>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="6" v-if="this.booking_details.booking_type!='1'">
						<b-form-group label="Amount">
							<b-input-group >
								<b-input-group-prepend is-text><b><i :class="{'mdi':true,[curncy]:true}"></i></b></b-input-group-prepend>
								
								
								<b-form-input  class="text-right"
								v-model="form.price"
								type="text"
								placeholder="Enter price"
								:state="validateState('price')"
								@input="validate_amount(1)"
								></b-form-input>
								<b-form-invalid-feedback id="first_name-1-live-feedback">
									<span v-if="!this.$v.form.price.required">
										Please enter price.</span
									>
									<span
									v-if="this.$v.form.price.required && !this.$v.form.price.numeric"
									>Price must be in numeric.</span
									>
									<span
									v-if="
									this.$v.form.price.required &&
									this.$v.form.price.numeric &&
									!this.$v.form.price.price_greater
									"
									>Price must be greater than 0.</span
									>
								</b-form-invalid-feedback>
							</b-input-group>
						</b-form-group>
						<div v-if="payment_error_message" class="text-danger">Please Check amount</div>
					</b-col>
					<b-col lg="6" md="6" sm="6">
						<b-form-group label="Payment mode">
							<b-form-select
							v-model="form.payment_mode"
							:options="payment_mode_option"
							placeholder="Select payment mode"
							track-by="value"
							label="text"
							>
							</b-form-select>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="6" v-if="this.form.payment_mode == 'cheque'">
						<b-form-group label="Cheque no.">
							<b-form-input
							placeholder="Enter Cheque no"
							type="text"
							v-model="form.cheque_no"
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col lg="6" md="6" sm="6" v-if="this.form.payment_mode == 'cheque'">
						<b-form-group label="Bank name">
							<b-form-input
							placeholder="Enter bank name"
							type="text"
							v-model="form.bankname"
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col  lg="6" md="6" sm="6"  v-if="this.form.payment_mode == 'online'" >
						<b-form-group label="Account Holder name">
							<b-form-input
							placeholder="Enter Account Holder name"
							type="text"
							v-model="form.holdername"
							></b-form-input>
						</b-form-group>
					</b-col> 
					<b-col  lg="6" md="6" sm="6"  v-if="this.form.payment_mode == 'online'" >
						<b-form-group label="Bank Name">
							<b-form-input
							placeholder="Enter Bank Name"
							type="text"
							v-model="form.bank_name"
							></b-form-input>
						</b-form-group>
					</b-col>
					<b-col lg="12" md="12" sm="12">
						
						
					</b-col>
				</b-row>
			</form>
		</b-modal>
		
		<b-modal id="modal-refund" title="Refund Payment"  ref='modal-refund'
		header-bg-variant="primary"
		header-text-variant="light"
		:centered="true"
		size="xl"
		hide-footer
		>
	
			<div class="">
				<div class="panel panel-primary panel-table mb-2"  v-if="baseprice_receipts.length>0">
              <div class="panel-heading">
                <div class="row">
                  <div class="col col-xs-6">
                    <h3 class="panel-title">Base Price</h3>
                  </div>
                  <div class="col col-xs-6 text-right" v-if="base_refunded_amount == 0 ">
                    <button type="button" class="btn btn-sm btn-primary btn-create" v-if="this.base_refund=='yes' && refund_disabled()==1" 
					@click="refund_amount(other_logs.booking_id,total_baseprice_bookings_price,'1')">Refund</button>
                  </div>
                </div>
              </div>
              <div class="panel-body">
				<table class="table table-bordered ">
					<thead>
						<tr>
							<th>Paid Date</th>
							<th>Receipt No</th>
							<th>Customer Name</th>
							<th class="text-right">Amount</th>
							<!-- <th class="text-right">Deducted</th>
							<th>Receipt type</th>
							<th>Action</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(refund , r) in baseprice_receipts" :key="r">
							<td> {{ date_format(refund.paid_date) }}</td>
							<td> {{ refund.receipt_no }}</td>
							<td> {{ refund.billing_first_name }}</td>
							<td class="text-right"> <span v-html="currency_check(formatPrice(refund.price ))"></span> </td>
							
						</tr>
						<tr v-if=" baseprice_refund_receipt && base_baseprice_receipt > 0">
							<td colspan="1">Deducted Date : {{ date_format(baseprice_refund_receipt.refund_date) }}</td>
							<td colspan="1">Deducted amount : <span v-html="currency_check(formatPrice(base_baseprice_receipt ))"></span></td>
							<td colspan="2">Reason : </td>
							
						</tr>
						<tr v-if="  baseprice_refund_receipt && this.baseprice_refund_receipt.amount>0">
							<td colspan="1">Refunded Date : {{ date_format(baseprice_refund_receipt.refund_date) }}</td>
							<td colspan="1">Refunded amount : <span v-html="currency_check(formatPrice(baseprice_refund_receipt.amount ))"></span></td>
							
						</tr>
					</tbody>
				</table>
			</div>
			</div>
			
					
<div class="panel panel-default panel-table mb-2" v-if="add_on_receipts.length>0">
              <div class="panel-heading">
                <div class="row">
                  <div class="col col-xs-6">
                    <h3 class="panel-title">Add-On</h3>
					<span v-if="this.form.balance.Total_amount<0">
					Extra Add-on Amount Refund  <span v-html="currency_check(formatPrice(-(this.form.balance.Total_amount) ))"></span>
					<b-button v-if="refund.refunded_amount==0" variant="success" size="sm" @click="refund_amount(other_logs.booking_id,refund.price,refund.receipt_type)"> Refund Active </b-button>
					<b-button v-else variant="danger" size="sm" > Refund done </b-button>
					</span>
                  </div>
                  <div class="col col-xs-6 text-right" v-if="add_refunded_amount == 0 ">
                    <button type="button" class="btn btn-sm btn-primary btn-create" v-if="this.add_refund=='yes' && refund_disabled()==1" 
					@click="refund_amount(other_logs.booking_id,total_add_on_bookings_price,'2')">Refund</button>
                  </div>
                </div>
              </div>
              <div class="panel-body">
				<table class="table table-bordered ">
					<thead>
						<tr>
							<th>Paid Date</th>
							<th>Receipt No</th>
							<th>Customer Name</th>
							<th class="text-right">Amount</th>
							<!-- <th class="text-right">Deducted</th>
							<th>Receipt type</th>
							<th>Action</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(refund , r) in add_on_receipts" :key="r">
							<td> {{ date_format(refund.paid_date) }}</td>
							<td> {{ refund.receipt_no }}</td>
							<td> {{ refund.billing_first_name }}</td>
							<td class="text-right"> <span v-html="currency_check(formatPrice(refund.price ))"></span> </td>
							
						</tr>
						<tr v-if="add_on_refund_receipt && this.add_baseprice_receipt>0">
							<td colspan="1">Deducted Date : {{ date_format(add_on_refund_receipt.refund_date) }}</td>
							<td colspan="1">Deducted amount : <span v-html="currency_check(formatPrice(this.add_baseprice_receipt ))"></span></td>
							<td colspan="2">Reason : <!-- {{ add_on_refund_receipt.refund_reason }} --></td>
						</tr>
						<tr v-if=" add_on_refund_receipt && this.add_on_refund_receipt.amount>0">
							<td colspan="1">Refunded Date : {{ date_format(add_on_refund_receipt.refund_date) }}</td>
							<td colspan="1">Refunded amount : <span v-html="currency_check(formatPrice(add_on_refund_receipt.amount ))"></span></td>
							
						</tr>
					</tbody>
				</table>
			</div>
			</div>
			

					
<div class="panel panel-default panel-table mb-2" v-if="refund_array.length>0">
              <div class="panel-heading">
                <div class="row">
                  <div class="col col-xs-6">
                    <h3 class="panel-title">Security Deposit </h3>
                  </div>
                  <div class="col col-xs-6 text-right" v-if="sd_refunded_amount == 0 ">
                    <button type="button" class="btn btn-sm btn-primary btn-create" v-if="this.sd_refund=='yes'  "
					@click="refund_amount(other_logs.booking_id,total_sec_bookings_price,'3')">Refund</button>
                  </div>
                </div>
              </div>
              <div class="panel-body">
				<table class="table table-bordered ">
					<thead>
						<tr>
							<th>Paid Date</th>
							<th>Receipt No</th>
							<th>Customer Name</th>
							<th class="text-right">Amount</th>
							<!-- <th class="text-right">Deducted</th>
							<th>Receipt type</th>
							<th>Action</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(refund , r) in refund_array" :key="r">
							<td> {{ date_format(refund.paid_date) }}</td>
							<td> {{ refund.receipt_no }}</td>
							<td> {{ refund.billing_first_name }}</td>
							<td class="text-right"> <span v-html="currency_check(formatPrice(refund.price ))"></span> </td>
							<!-- <td class="text-right"> <span v-html="currency_check(formatPrice(refund.refunded_amounts ))"></span> </td>
							<td> {{ refund.receipt_type==1 ? "Base Price" : refund.receipt_type==2 ? "Add-On":" Security Deposit" }}</td>
							<td>
								<b-button v-if="refund.refunded_amount==0" variant="danger" size="sm" @click="refund_amount(other_logs.booking_id,refund.price,refund.receipt_type)"> Refund </b-button>
								<b-button v-else variant="success" size="sm" > Refunded </b-button>
							</td> -->
						</tr>
						<tr v-if="sd_refund_receipt && this.sd_baseprice_receipt>0">
							<td colspan="1">Deducted Date : {{ date_format(sd_refund_receipt.refund_date) }}</td>
							<td colspan="1">Deducted amount : <span v-html="currency_check(formatPrice(this.sd_baseprice_receipt ))"></span></td>
							<td colspan="2">Reason : <!-- {{ sd_refund_receipt.refund_reason }} --></td>
						</tr>
						<tr v-if="sd_refund_receipt && this.sd_refund_receipt.amount>0">
							<td colspan="1">Refunded Date : {{ date_format(sd_refund_receipt.refund_date) }}</td>
							<td colspan="1">Refunded amount : <span v-html="currency_check(formatPrice(sd_refund_receipt.amount ))"></span></td>
							
						</tr>
						<!-- <tr v-if="this.form.balance.Total_amount<0">
							<td></td>
							<td></td>
							<td></td>
							
							<td><span v-html="currency_check(formatPrice(-(this.form.balance.Total_amount) ))"></span></td>
							<td>AddOn Refund Amount</td>
							<td>
								<b-button  variant="danger" size="sm" @click="RefundAddon" v-if="this.booking_form_details.refund_paid==0"> Refund </b-button>
								<b-button  variant="success" size="sm"  v-else> Refunded </b-button>
							</td>
						</tr> -->
						
					</tbody>
				</table>
			</div>
			</div>

				
				
				
			</div>
			
		</b-modal>
		
	</div>
</template>

<script>
	import axios from "axios";
	import helpers from '../../mixins/helpers';
	import { validationMixin } from "vuelidate";
	import { VueEditor } from "vue2-editor";
	import {
		required,
		numeric,
	} from "vuelidate/lib/validators";
	const price_greater = (value) => (value > -1); //price should be greater 0
	
	
	
	export default {
		mixins: [validationMixin,helpers],
		data() {
			return {
				today_date: new Date().toISOString().substr(0, 10),
				date_refund: new Date().toISOString().substr(0, 10),
				customToolbar: [["bold"], [{ list: "ordered" }, { list: "bullet" }]],
				remarks: '',
				remark: '',
				rec_type: '',
				editTrue: false,
				animate: true,
				tax_amount_view: false,
				refund_status: false,
				reason_status: false,
				refund_amt_tax: 0,
				refund_amt: '',
				ref_amount: '',
				reason_for: "",
				total_security_amount: 0,
				bookid: 0,
				refunded_amount: 0,
				new_booking_logs:[],
				addon_id:[],
				tasks:[],
				booking_logs:[],
				booking_details:[],
				payment_logs:[],
				settlement_logs:[],
				events_logs:[],
				other_logs:'',
				logs:[],
				status:'',
				newtask_name:'',
				permission_access:'',
				allowcancel:'',
				event:{
					add_ready:false,
					add_ready1:false,
					add_ready2:false,
					add_ready3:false,
					add_ready4:false,
					add_ready5:false,
				},
				booking_details1:'',
				recipt_type:'',
				curncy:'',
				users:'',
				ClosingDetails:'',
				allClaseData:'',
				label_name:'Remark',
				cliked:'',
				label_id:'',
				percentage: 0,
				remender_per: 0,
				event_remain_day_check: 0,
				event_per: 0,
				animateStart: 0,
				soundurl : 'http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3',
				form:
				{
					receipt_type: "1",
					payment_mode: "cash",
					price:0,
					price1:0,
					price2:0,
					payment_date:'',
					balance:0,
					bank_name:'',
					holdername:'',
					bankname:'',
					cheque_no:'',
				total_amounts:''},
				payment_error_message:false,
				payment_error_message1:false,
				payment_error_message2:false,
				payment_mode_option: [
				{ value: "cash", text: "Cash" },
				{ value: "online", text: "Online" },
				{ value: "cheque", text: "Cheque" }
				],
				Receipt_types: [
				{ value: "1", text: "Base price" },
				{ value: "2", text: "Add-Ons" },
				{ value: "3", text: "Security Deposit" }
				],
				options: [
				{ item: 'daily', name: 'Daily' },
				{ item: '2', name: '2 Days' },
				{ item: '7', name: 'Week ' },
				],

				refund_array:[],
				add_on_receipts :[],
				baseprice_receipts:[],

				base_refunded_amount:0,//base_refunded_amount 
				base_baseprice_receipt:0,//base_baseprice_receipt 
				base_refund:0,//base_refund 
				total_baseprice_bookings_price:0,//base_refund 
				baseprice_refund_receipt:'',

				add_refunded_amount:0,//add_refunded_amount 
				add_baseprice_receipt:0,//add_baseprice_receipt 
				add_refund:0,//add_refund 
				total_add_on_bookings_price:0,
				add_on_refund_receipt:'',


				sd_refunded_amount:0,//sd_refunded_amount 
				sd_baseprice_receipt:0,//sd_baseprice_receipt 
				sd_refund:0,//sd_refund 
				total_sec_bookings_price:0,//total_sec_bookings_price 
				sd_refund_receipt:'',//total_sec_bookings_price 

				booking_form_details:{  
					booking_id:'',
					child_venue_id:'',
					selected_addons:[],
					selected_addons_ids:[],
					add_on_total:0,
					sub_total:0,
					cgst_amount:0,
					sgst_amount:0,
					final_total:0,
					after_discounted_amount:0,
					applied_discount:0,
					outstanding_amount:0,
					bookings_price:[],
					
					bookings_all_paid_price:0,
					receipt_total:0,
					refund_paid:0,
				},
				task:
				{
					name:'',
					check:'',
					remainder_date:'',
					after_remainder_date:'',
					repeat_after_checked:'',
					sound_after_checked:'',
					sound_before_checked:'',
					repeat_before_checked:'',
					time_before:'',
					time_after:'',
					repeat_remainder:'daily',
					repeat_remainder1:'daily',
				},
				discount_percentage:0,
				/* Sticky */
			scrollY: null,
      headerTop: 0,
      isHeaderSticky: false,
			}
		},
		components: { VueEditor },
		validations: {
			form: {
				price: {  numeric, price_greater },
				price1: { numeric, price_greater },
				price2: {  numeric, price_greater },
				payment_date: { required},
			},
		},
		methods:
		{
			search_log()
			{
				axios.post("/api/load_booking_logs",{booking_ref_id:this.booking_ref_id}).then((resp) => {
					//    console.log(JSON.parse(resp.data.booking_logs.booking_logs)[0]);
					this.other_logs=resp.data.booking_logs;
					this.remarks=resp.data.booking_logs.question;
					this.new_booking_logs=resp.data.booking_logs.new_booking_logs;
					this.booking_logs=JSON.parse(resp.data.booking_logs.booking_logs);
					this.payment_logs=JSON.parse(resp.data.booking_logs.payment_logs);
					this.events_logs=JSON.parse(resp.data.booking_logs.event_logs);
					this.settlement_logs=JSON.parse(resp.data.booking_logs.settlement_logs);
					this.tasks=JSON.parse(resp.data.booking_logs.tasks);
					this.allowcancel=JSON.parse(resp.data.booking_logs.all_settings).allowcancel ? 1 :0 ;//allowcancel
					
					this.percentage = parseInt(resp.data.booking_logs.event_percentage)+parseInt(resp.data.booking_logs.event_percentage_completed);
					
					this.remender_per = this.other_logs.remender_per;
					this.event_per = this.other_logs.event_per;
					this.event_remain_day_check = this.other_logs.event_remain_day_check;
					
					/* setTimeout(() => {
						this.updateProgressBar(this.percentage);
					},2000); */
					
					this.checking();
					
					this.discount_percentage =(this.other_logs.applied_discount/this.other_logs.total_booking_price*100).toFixed(2);
					
					
					
					
					this.getBookingsPriceDetails();
					this.check_receipt_type();
					this.get_booking_details();
					
					/* if(this.payment_logs==null)
						{
						this.status='Booking';
						}
						else if(this.events_logs==null)
						{
						this.status='Payment';
						}
						else if(this.settlement_logs==null)
						{
						this.status='Event';
						}
						else
						{
						this.status='Settlemened';
						}
						for(var m=0;m<=this.events_logs.length;m++)
						{
						if(this.events_logs[m].desc=='Add-ons Ready')
						{
						this.event.add_ready=true;
						}
						if(this.events_logs[m].desc=='Special request Ready')
						{
						this.event.add_ready1=true;
						}
						if(this.events_logs[m].desc=='Venue Maintenance')
						{
						this.event.add_ready2=true;
						}
						if(this.events_logs[m].desc=='Venue Cleaning')
						{
						this.event.add_ready3=true;
						}
						if(this.events_logs[m].desc=='Venue Arrangement')
						{
						this.event.add_ready4=true;
						}
						if(this.events_logs[m].desc=='Venue Ready')
						{
						this.event.add_ready5=true;
						}
						this.ClosingDetails=JSON.parse(resp.data.booking_logs.settlement_logs);
						
						if(this.ClosingDetails.reverse()[0].logs=='Invoice Generated')
						{
						this.allClaseData=this.ClosingDetails[0];
						} 
						
					} */
				});
			},
			check_receipt_type()
			{
				axios.post("/api/get_pending_amount_details", {
					booking_id: this.other_logs.booking_id,
					receipt_type: this.form.receipt_type,
					child_venue_id: this.other_logs.child_venue_id ,
				})
				.then((resp) => {
					this.form.balance=resp.data;
					
				});
			},
			validateState(name) {
				const { $dirty, $error } = this.$v.form[name];
				return $dirty ? !$error : null;
			},
			SaveEvent()
			{
				axios.post("/api/save_event_logs",{booking_ref_id:this.booking_ref_id ,events : this.event}).then((resp) => {
					resp
					this.search_log();
				})
			},
			
			link_check(id)
			{
				this.receipt_print(id)
				/* if(check=='advance' || check=='Add-ons Confirmed')
					{
					this.$router.push('/my-booking/details/'+id)
					}
					else if(check=='Profoma Invoice')
					{
					this.$router.push('/my-booking/invoice/'+this.other_logs.booking_id)
					}
					else if(check=="Receipt Issued - Base Price" || check=="Receipt Issued - Add-ons" || check=="Receipt Issued - Security Deposit")
					{
					this.receipt_print(id)
					}
					else if(check=="Invoice Generated")
					{
					this.$router.push('/finance/invoice/'+this.other_logs.booking_id)
				} */
				
				// alert(check)
				//my-booking/details/c350e33d-0f99-4231-a22a-fb5b8c0cc32c
			},
			receipt_print(bid)
			{
				axios.post("/api/receipt_details",{id:bid}).then((resp) => {
					
					this.booking_details1= resp.data;
					this.recipt_type= resp.data.receipt_type;
					
				});
				this.$refs['parent_child_venue'].show()
				
			},
			getProfile() {
				axios.get("/api/get_profile").then((resp) => {
					if (resp.data.status_code == 200) {
						this.curncy= resp.data.users.currencys;
						this.users= resp.data.users;
						this.logo= process.env.VUE_APP_APIURL+'/'+resp.data.users.logo;
					}
				});
			},
			date_format(date)
			{
				var d1 = new Date(date);
				var ms = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
				return d1.getDate() + '' + this.getDateOrdinal(d1) +' ' + ms[d1.getMonth()] + ' ' + d1.getFullYear();
				
			},
            getDateOrdinal(date) {
                let day = date.getDate();
                if (day > 3 && day < 21) return 'th';
                switch (day % 10) {
					case 1:  return "st";
					case 2:  return "nd";
					case 3:  return "rd";
					default: return "th";
				}
			},
			checkShift(type)
			{
				if(type==1)
				{
					return "Morning";
				}  
				else if(type==2)
				{
					return "Afternoon";
				}  
				else if(type==3)
				{
					return "Evening";
				}
			},
			inWordscheck(number) {
				if ((number < 0) || (number > 999999999)) 
				{ 
					return "NUMBER OUT OF RANGE!";
				}
				var Gn = Math.floor(number / 10000000);  /* Crore */ 
				number -= Gn * 10000000; 
				var kn = Math.floor(number / 100000);     /* lakhs */ 
				number -= kn * 100000; 
				var Hn = Math.floor(number / 1000);      /* thousand */ 
				number -= Hn * 1000; 
				var Dn = Math.floor(number / 100);       /* Tens (deca) */ 
				number = number % 100;               /* Ones */ 
				var tn= Math.floor(number / 10); 
				var one=Math.floor(number % 10); 
				var res = ""; 
				
				if (Gn>0) 
				{ 
					res += (this.inWordscheck(Gn) + " Crore"); 
				} 
				if (kn>0) 
				{ 
					res += (((res=="") ? "" : " ") + 
					this.inWordscheck(kn) + " Lakh"); 
				} 
				if (Hn>0) 
				{ 
					res += (((res=="") ? "" : " ") +
					this.inWordscheck(Hn) + " Thousand"); 
				} 
				
				if (Dn) 
				{ 
					res += (((res=="") ? "" : " ") + 
					this.inWordscheck(Dn) + " Hundred"); 
				} 
				
				
				var ones = Array("", "One", "Two", "Three", "Four", "Five", "Six","Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen","Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eightteen","Nineteen"); 
				var tens = Array("", "", "Twenty", "Thirty", "Fourty", "Fifty", "Sixty","Seventy", "Eighty", "Ninety"); 
				
				if (tn>0 || one>0) 
				{ 
					if (!(res=="")) 
					{ 
						res += " And "; 
					} 
					if (tn < 2) 
					{ 
						res += ones[tn * 10 + one]; 
					} 
					else 
					{ 
						
						res += tens[tn];
						if (one>0) 
						{ 
							res += ("-" + ones[one]); 
						} 
					} 
				}
				
				if (res=="")
				{ 
					res = "zero"; 
				} 
				return res;
			},
			number2text(value) {
				var fraction = Math.round(this.frac(value)*100);
				var f_text  = "";
				
				if(fraction > 0) {
					f_text = "And "+this.inWordscheck(fraction)+" ";
				}
				
				return 'Rupees '+this.inWordscheck(value)+" "+f_text+" Only";
			},
			
			frac(f) {
				return f % 1;
			},
			convert_24_12hour(timeString)
			{
				const [hourString, minute] = timeString.split(":");
				const hour = +hourString % 24;
				return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM"); 
			},
			check_shift(shifts)
			{
				if(shifts==1)
				{
					return "Morning";
				}
				else if(shifts==1)
				{
					return "Afternoon";
				}
				else if(shifts==1)
				{
					return "Evening";
				}
			},
			formatPrice(value) {
				//let val = (value/1).toFixed(2).replace(',', '.')
				
				var num = value ? value : 0;
				return num.toLocaleString('en-IN')
				
				
			},
			calculateSum(){
				var sum = 0;
				for(var k=0; k< this.other_logs.recept_booking.length;k++){
					sum += this.other_logs.recept_booking[k].price
					//console.log(val)
				}
				return sum;
			},
			currency_check(amt) {
				
				
				return '<i class="mdi '+this.curncy+'"></i>'+amt;
			},
			closeReceipt()
			{
				this.$refs['parent_child_venue'].hide()
			},
			closeTask()
			{
				this.$refs['modal-add-task'].hide();
				this.$refs['parent_child_venue'].hide();
				this.$refs['parent_child_venue1'].hide()
			},
			refund_amount(book_id,refunded_amount,type)
            {
                this.bookid=book_id;
				this.refund_amt='';
				this.ref_amount=refunded_amount;
				this.refund_amt_tax='';
				this.refunded_amount=refunded_amount;
				this.rec_type=type;
				axios.post("/api/refund_amounts",{id:book_id,receipt_type:type}).then((resp) => {
					
					this.total_security_amount = resp.data;
					
				});
				this.Auto_calculation();
                this.$refs['parent_child_venue1'].show()
			},
            taxCalculate()
			{
				
				this.tax_amount_view=true;
			},
            Auto_calculation()
			{
				var taxamt=0;
				var ref_amount =0;
				if(this.rec_type==3)
				{

					taxamt=this.refund_amt*18/100;
					this.refund_amt_tax=Math.round(parseFloat(taxamt)+parseFloat(this.refund_amt));
				ref_amount = this.refunded_amount - this.refund_amt_tax;
				}
				else
				{
					taxamt=0;
					this.refund_amt_tax=0;
				ref_amount = this.refunded_amount;
				}
				
				
				if(this.refund_amt_tax >= 0 &&  ref_amount >= 0 )
				{
					this.ref_amount =  ref_amount;
				}
				else
				{
					this.ref_amount = ref_amount;
					this.refund_amt_tax = '';
					this.refund_status=true;
				}
			},
			checkrefund_amount()
			{
				
				this.refund_status=false;
				this.reason_status=false;
				
                if(this.refund_amt<0)
				{
					this.refund_status=true;
					this.Auto_calculation();
				}
				else if(this.refund_amt==0)
				{
					this.Auto_calculation();
					this.refund_status=false;
				}
				else if(this.refund_amt > 0  )
				{
					if(this.reason_for=='')
					{
						this.refund_status=false;
						this.reason_status=true;
					}
					else
					{
						this.refund_status=false;
						this.reason_status=false;
						this.Auto_calculation();
					}
				}
			},
			Save_refund_amount()
			{
				var flag=0;
				if(flag==0)
				{
					this.loader_appy=true;
					axios.post("/api/Save_refund_amount",
					{
						booking_id:this.bookid,
						receipt_type:this.rec_type,
						refundamt : this.refund_amt,
						date_of_refund : this.date_refund,
						deduct_amount : this.ref_amount,
						reason_for : this.reason_for,
					})
					.then((resp) => {
						
						if (resp.data.status) {
							this.$root.$refs.app.showToast("success",'Refund amount deducted successfull');
							this.Refund_payment();
							this.search_log();
							} else {
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
						this.$refs['parent_child_venue1'].hide()
						this.loader_appy=false;
					});
					flag++;
				}
			},
			update_addon(evt,add_id,add_name)
			{
				var check;
				var check_setting;
				var date_check;
				if (evt.target.checked)
				{
					check=true;
					check_setting='Check';
					date_check = this.date_format(this.today_date);
				}
				else
				{
					check=false;
					check_setting='Uncheck';
					date_check = '';
				}
				this.$swal({
					title: 'Are you sure to '+check_setting +' Add-on?',
					text: "Please  confirm ! Date is "+date_check,
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
					}).then((result) => {
					if (result.value) {
						
						axios.post("/api/update_addon_status",
				{
					check:check,
					add_id:add_id,
					add_name:add_name,
					booking_id : this.other_logs.booking_id 
				})
				.then((resp) => {
					resp
					this.search_log();
				});
					}
				}); 
				
				
			},
			enable_taks_check(evt,task_name)
			{
				var check_setting;
				var date_check;
				if (evt.target.checked)
				{
					this.task.check =true;
					check_setting="Check";
					date_check = this.date_format(this.today_date);
				}
				else
				{
					this.task.check =false;
					check_setting="Uncheck";
					date_check="";
				}
				this.task.name =task_name;	
				this.$swal({
					title: 'Are you sure to '+check_setting +' Task & Remainder?',
					text: "Please  confirm ! Date is "+date_check,
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
					}).then((result) => {
					if (result.value) {
						
						
						axios.post("/api/update_task_status",
				{
					check:this.task.check,
					taskname:this.task.name,
					booking_id : this.other_logs.booking_id 
				})
				.then((resp) => {
					resp
					this.search_log();
					
				});
					}
				}); 
				

			},
			update_task(evt,task_name,task_status,task)
			{
				/* if (evt.target.checked)
					{
					this.task.check =true;
					this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
					}
					else
					{
					this.task.check =false;
				} */
				this.task.name =task_name;		
				this.task.check =task_status;	
				this.task.remainder_date =task.remainder_date;	
				this.task.after_remainder_date =task.after_remainder_date;	
				this.task.repeat_after_checked =task.repeat_after_checked;	
				this.task.sound_after_checked =task.sound_after_checked;	
				this.task.sound_before_checked =task.sound_before_checked;	
				this.task.repeat_before_checked =task.repeat_before_checked;	
				this.task.time_before =task.time_before;	
				this.task.time_after =task.time_after;	
				
				this.task.repeat_remainder =task.repeat_remainder;	
				this.task.repeat_remainder1 =task.repeat_remainder1;	
				
				this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
				
			},
			update_taks_remainder()
			{
				
				axios.post("/api/update_task_remainder",
				{
					taskname:this.task.name,
					remainder_date:this.task.remainder_date,
					booking_id : this.other_logs.booking_id,
					after_remainder_date:this.task.after_remainder_date,
					repeat_after_checked:this.task.repeat_after_checked,
					sound_after_checked:this.task.sound_after_checked,
					sound_before_checked:this.task.sound_before_checked,
					repeat_before_checked:this.task.repeat_before_checked,
					time_before:this.task.time_before,
					time_after:this.task.time_after,
					
					repeat_remainder:this.task.repeat_remainder,
					repeat_remainder1:this.task.repeat_remainder1,
				})
				.then((resp) => {
					resp
					this.search_log();
					
				});
			},
			newtask()
			{
				axios.post("/api/new_task_create",
				{
					task:this.newtask_name,
					booking_id : this.other_logs.booking_id 
				})
				.then((resp) => {
					resp
					this.newtask_name="";
					this.search_log();
					this.closeTask();
				});
			},
			addon_comment(add_on,id)
			{
				this.label_name=add_on;
				this.label_id=id;
				this.cliked="Addon";
			},
			task_comment(task_name)
			{
				this.label_name=task_name;
				this.cliked="Task";
			},
			add_comment_create()
			{
				axios.post("/api/new_comment_add",
				{
					cliked:this.cliked,
					label_name:this.label_name,
					label_id:this.label_id,
					comment:this.remark,
					booking_id : this.other_logs.booking_id 
				})
				.then((resp) => {
					resp
					this.remark="";
					this.search_log();
				});
			},
			add_comment_creates(labelname,id,clicks)
			{
				axios.post("/api/new_comment_add",
				{
					cliked:clicks,
					label_name:labelname,
					label_id:id,
					comment:this.remark,
					booking_id : this.other_logs.booking_id 
				})
				.then((resp) => {
					resp
					this.remark="";
					this.search_log();
				});
			},
			getBookingsPriceDetails(){
				
				axios.post("/api/get_booking_price_details", {
					booking_id: this.other_logs.booking_id,
				})
				.then((resp) => {
					if (resp.data.status_code == 200 && resp.data.status) {
						console.log(resp.data);
						
						this.booking_form_details.bookings_price = resp.data.booking_prices;
						this.booking_form_details.refund_paid = resp.data.refund_paid;
						
						
						this.booking_form_details.bookings_all_paid_price = 0;
						
						for(var i=0; i < this.booking_form_details.bookings_price.length; i++){
							this.booking_form_details.bookings_all_paid_price = parseInt(this.booking_form_details.bookings_all_paid_price) + parseInt(this.booking_form_details.bookings_price[i]['price']);
						}
						//	this.makeGrandTotal();
						if(this.booking_form_details.bookings_price.length==0)
						{   
							
							var amt=this.booking_details.paid_price;
							var base_amt;
							var addon_amt;
							var security_amt;
							
							if(amt <= this.form.balance.base_price ) //40000<=32000
							{
								//alert('1')
								base_amt= amt;
							}
							else if(amt > this.form.balance.base_price) //40000>32000
							{
								//alert('e1')
								base_amt= this.form.balance.base_price;
							}
							/*   base price perfect  */
							
							var xyz = amt - base_amt; 
							
							if(xyz <= this.form.balance.add_total )
							{
								addon_amt= xyz;
							}
							else if(xyz > this.form.balance.add_total)
							{
								addon_amt= this.form.balance.add_total;
							}
							
							var lmn = xyz - addon_amt;
							
							if(lmn <= this.form.balance.security_deposit )
							{
								security_amt= lmn;
							}
							else if(lmn > this.form.balance.security_deposit)
							{
								//alert('e3')
								security_amt= this.form.balance.security_deposit;
							}
							
							this.form.price= base_amt;
							this.form.price1=addon_amt;
							this.form.price2=security_amt;
							this.form.total_amounts=amt;
							
							this.form.payment_date=new Date();
							/* 	this.form.bank_name=JSON.parse(this.booking_details.pay_online_info).bank_name;
								this.form.holdername=JSON.parse(this.booking_details.pay_online_info).online_name;
								this.form.bankname=this.booking_details.bank_name;
							this.form.cheque_no=this.booking_details.cheque_no; */
							this.form.payment_mode=this.booking_details.payment_mode;
							
						}                                   
					} 
				});
			},
			get_booking_details() {
				
				axios
				.post("/api/get_booking_detail", {
					booking_id: this.other_logs.booking_id,
				})
				.then((resp) => {
					if (resp.data.status) {
						
						this.booking_details = resp.data.booking_detail[0];
						this.booking_form_details.child_venue_id = resp.data.booking_detail[0].child_venue_id;
						if(this.booking_details.add_on_details.length > 0){
							this.booking_form_details.selected_addons_ids = JSON.parse(this.booking_details.add_on_details);
							//this.getChildVenueDetails();
							this.getBookingsPriceDetails();
							
							
							for(var i=0;i<resp.data.booking_detail[0].add_ons.length;i++)
							{
								var tamt = resp.data.booking_detail[0].add_ons[i].qty*resp.data.booking_detail[0].add_ons[i].price;
								this.booking_form_details.add_on_total+=tamt;
							}
						}
						this.booking_logs=JSON.parse(this.booking_details.booking_logs);
					}
				});
			},
			validate_amount(type)
			{
				this.form.total_amounts=parseInt(this.form.price ? this.form.price : 0 ) + parseInt(this.form.price1  ? this.form.price1 : 0)  + parseInt(this.form.price2  ? this.form.price2 : 0)  ;
				if(type==1)
				{
					this.payment_error_message=false;
					if(this.form.price>this.form.balance.base_price)
					{
						this.payment_error_message=true;
					}
				}
				if(type==2)
				{
					this.payment_error_message1=false;
					if(this.form.price1>this.form.balance.add_total)
					{
						this.payment_error_message1=true;
					}
				}
				if(type==3)
				{
					this.payment_error_message2=false;
					if(this.form.price2>this.form.balance.security_deposit)
					{
						this.payment_error_message2=true;
					}
				}
				
			},
			handleOk(bvModalEvent) {
				bvModalEvent.preventDefault()
				this.$v.form.$touch();
				if (this.$v.form.$anyError) {
					return;
					} else {
					this.savePrice();
				}
			},
			savePrice() {
				this.$v.form.$touch();
				if (!this.$v.form.$anyError) {
					axios.post("/api/save_booking_prices", {
						payment_mode: this.form.payment_mode,
						price: [this.form.price,this.form.price1,this.form.price2],
						balance: [this.form.balance.base_price,this.form.balance.add_total,this.form.balance.security_deposit],
						/* price1: this.form.price1,
						price2: this.form.price2, */
						receipt_type: this.booking_details.booking_type!='1' ? 4 : [1,2,3],
						payment_date: this.form.payment_date,
						bank_name: this.form.bank_name,
						holdername: this.form.holdername,
						bankname: this.form.bankname,
						cheque_no: this.form.cheque_no,
						booking_id: this.other_logs.booking_id,
					})
					.then((resp) => {
						
						if (resp.data.status_code == 200 && resp.data.status) {
							this.$root.$refs.app.showToast("success", resp.data.message);
							this.$bvModal.hide('modal-booing-price');
							this.search_log();
							this.check_receipt_type();
							
							this.form.price="";
							this.form.price2="";
							this.form.price1="";
							this.form.payment_date=new Date();
							this.form.bank_name="";
							this.form.holdername="";
							this.form.bankname="";
							this.form.cheque_no="";
							this.form.payment_mode="";
							this.form.total_amounts=0;
							
							}else{
							this.$root.$refs.app.showToast("danger", resp.data.message);
						}
					});
				}
			},
			getColor: function(item) {
				if (item > 80) {
					return 'bg-success'
					} else if (item > 90) {
					return 'bg-danger'
					} else if (item > 100) {
					return 'bg-success'
				}
				return ''
			},
			checking()
			{
				
				/* if(this.percentage < 80 && this.percentage > 90 )
					{
					
					this.animateStart = "start Valuationnndsd"
				} */
				if(this.percentage >= this.remender_per && this.percentage <= 90){
					this.animateStart = "start_box";
					/* 	var audioFile
						setInterval(() => {
						audioFile = new Audio('http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3');
						
						},1000);
					//	audioFile.play(); */
					
				}
				else if(this.percentage >= 90 && this.percentage < 100){
					this.animateStart = "Closing"
				}
				else if(this.percentage == 100)
				{
					this.animateStart = "Closed"
				}
			},
			
			
			updatePercentage(level) {
				
				var percentage = document.getElementById("percentage");
				// percentage.innerText = level + "%";
				percentage.style.marginLeft = level + "%";
				
				if (level == 120) {
					// if percentage > 90% translateX -37px
					percentage.style.transform = "translateX(-37px)";
					} else if (level <= 119 && level >= 10) {
					// if percentage btw 90 and 10 translateX -28px
					percentage.style.transform = "translateX(-28px)";
					} else if (level <= 119 && level >= 4) {
					// if percentage < 10% don't translate
					percentage.style.transform = "translateX(-18px)";
					} else if (level < 4 && level >= 1) {
					percentage.style.transform = "translateX(-5px)";
				}
			},
			
			updateProgressBar(level) {
				var progressLevel = document.getElementById("progressLevel");
				progressLevel.style.width = level + "%";
				this.updatePercentage(level);
				
				if (level < 120) {
					progressLevel.classList.remove("fullbar");
					} else {
					progressLevel.classList.add("fullbar");
				}
			},
			editRemark()
			{
				this.editTrue=!this.editTrue;
			},
			saveRemark()
			{
				this.editTrue=false;
				axios.post("/api/save_remarks", {
					booking_id: this.other_logs.booking_id,
					question: this.remarks,
				})
				.then((resp) => {
					resp;
					this.$root.$refs.app.showToast("success", 'Remark Updated');
					this.search_log();
				});
			},
			receipt_print_page()
			{
				var divContents = document.getElementById("print_layout").innerHTML;
				
				var a = window.open('', '', 'height=600, width=1000');
				a.document.write('<html><style> #invoiceTable {border-collapse: collapse;width: 100% !important;margin-left: 0 !important;}#invoiceTable td, #invoiceTable th {border: 1px solid #282424;padding: 8px;} #invoiceTable th {padding-top: 12px;padding-bottom: 12px;text-align: left;color: white;} #wa-95 { width:89%; important ; font-size:15px; } #war-40 { width:40%; important ; }  #w-20 { width:20%; important ; } #cw-75 { width:50%; !important;} .w-50 { width:100% } #InsidePaymentTable td {border: 0 } #InsideamountTable td {   border:none ; border-bottom: 1px solid #ddd; } #InsideamountTable td.line-sides { border-right:1px solid #ddd }</style>');
				a.document.write('<body class="bodycolor">');
				a.document.write(divContents);
				a.document.write('</body>');
				a.document.write('<div style="position:fixed;bottom:0;border-top:1px solid #d9d9d9;width:100%;text-align:center">Computer Generated Invoice No Need of Signature </div></html>');
				
				a.document.close();
				a.onload = function () { a.print(); a.close(); }
			},
			access_permission()
			{
				axios
				.post("/api/access_permission_all").then((resp) => {
					for(var i=0; i< resp.data.length;i++)
					{
						if(resp.data[i].subname=='Create Booking' )
						{
							this.permission_access= resp.data[i];
						}
						
						
					}
				});
			},
			Refund_payment()
			{
				this.$refs['modal-refund'].show()
				axios
				.post("/api/refund_payment_list",{
					booking_id: this.other_logs.booking_id,
					child_venue_id: this.other_logs.child_venue_id,
					}).then((resp) => {
					this.baseprice_receipts=resp.data.baseprice_receipts;
					this.add_on_receipts=resp.data.add_on_receipts;
					this.refund_array=resp.data.booking_prices_securit;  

					this.base_refunded_amount=resp.data.base_refunded_amount;  
					this.base_baseprice_receipt=resp.data.base_baseprice_receipt;  
					this.base_refund=resp.data.base_refund;  
					this.total_baseprice_bookings_price=resp.data.total_baseprice_bookings_price;  
					this.baseprice_refund_receipt=resp.data.baseprice_refund_receipt;  

					this.add_refunded_amount=resp.data.add_refunded_amount;  
					this.add_baseprice_receipt=resp.data.add_baseprice_receipt;  
					this.add_refund=resp.data.add_refund;  
					this.total_add_on_bookings_price=resp.data.total_add_on_bookings_price;  
					this.add_on_refund_receipt=resp.data.add_on_refund_receipt;  

					this.sd_refunded_amount=resp.data.sd_refunded_amount;  
					this.sd_baseprice_receipt=resp.data.sd_baseprice_receipt;  
					this.sd_refund=resp.data.sd_refund;  
					this.total_sec_bookings_price=resp.data.total_sec_bookings_price;  
					this.sd_refund_receipt=resp.data.sd_refund_receipt;  

					//

				});
			},
			generate_invoice(booking_id)
			{
				this.$swal({
					title: 'Are you sure to Generate Invoice?',
					text: "please  confirm !",
					type: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Generate'
					}).then((result) => {
					if (result.value) {
						
						axios.get("/api/invoiceGenerated?booking_id="+booking_id).then((resp) => {
							resp
							this.search_log();
						});
					}
				}); 
				
			},
			cancel_booking()
			{
				this.$swal({
					
					title: 'Are you sure to Cancel Booking?',
					text: "please  confirm !",
					type: 'warning',
					icon: "warning",
					showClass: {
						popup: `
						animate__animated
						animate__fadeInUp
						animate__faster
						`
					},
					hideClass: {
						popup: `
						animate__animated
						animate__fadeOutDown
						animate__faster
						`
					},
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes, Cancel'
					}).then((result) => {
					if (result.value) {
						
						axios.get("/api/cancel_booking?booking_id="+this.other_logs.booking_id).then((resp) => {
							resp
							this.search_log();
						});
					}
				}); 
			},
			RefundAddon()
			{
				axios.post("/api/refund_addon_amount",{
					booking_id:this.other_logs.booking_id,
					Amount : this.form.balance.Total_amount,
					type:6
					}).then((resp) => {
					resp
					this.search_log();
				});
			},
			refund_disabled() 
			{
				if(this.today_date>this.other_logs.from_date)
				{
					return 0;

				}
				else
				{
					return  1;
				}
				
			}
		},
		mounted() {
			window.addEventListener('scroll', () => {
      window.addEventListener('scroll', () => {
        this.scrollY = Math.round(window.scrollY);
      });
      this.headerTop = this.$refs.side.getBoundingClientRect().top;
	});
			this.booking_ref_id=this.$route.params.id;
			this.search_log();
			this.getProfile();
			this.access_permission();
			
			document.documentElement.scrollTop = 0;
		},
		computed: {
			percent() {
				return this.percentage.toFixed();
			}
		},
		created() {
			// setInterval(this.search_log, 5000);
		},
		watch: {
    scrollY(newValue) {
      if (newValue > this.headerTop) {
        this.isHeaderSticky = true;
      } else {
        this.isHeaderSticky = false;
      }
    }
},
		
	}
</script>

<style>
	
	.cursor-pointer
	{
	cursor: pointer;
	}
	.cursor-pointer:hover{
	color: #A44BF3;
	}
	.timeline .timeline-item .timeline-points {
    position: absolute;
    left: -0.7rem;
    top: -20px;
    z-index: 2;
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    background-color: #7367f0;
	}
	.ml-100
	{
	margin-left: 28%;
	}
	.w-fix
	{
    width: 93% !important;
	}
	.header-card-color
	{
	background-image: linear-gradient(242deg, #A44BF3 0%, #499CE8 100%);
	color: white;
	}
	.mt--15
	{
	margin-top: -15px;
	}
	.saleem-date
	{
	position: absolute;
    left: -165px;
    top: -16px;
	
	}
	.ml-16
	{
	margin-left: 16%;
	}
	#invoiceTable {
	font-family: Arial, Helvetica, sans-serif;
	border-collapse: collapse;
	width: 100%;
	}
	
	#invoiceTable td, #invoiceTable th {
	border: 1px solid #ddd;
	padding: 8px;
	}
	/*
	#invoiceTable tr:nth-child(even){background-color: #f2f2f2;} */
	
	
	#invoiceTable th {
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: left;
	
	color: white;
	}
	
	.anyClass {
	overflow-y: scroll;
	}
	div .anyClass {
	-ms-overflow-style: none; /* for Internet Explorer, Edge */
	scrollbar-width: none; /* for Firefox */
	overflow-y: scroll; 
	}
	
	div .anyClass::-webkit-scrollbar {
	display: none; /* for Chrome, Safari, and Opera */
	}
	.new_progress {
    display: flex;
    height: 15px;
    overflow: hidden;
    line-height: 3;
    font-size: 0.65625rem;
    background-color: #ddebf9;
    border-radius: 4px;
	}
	
	/*progressbar*/
	#progressbar {
    margin-top: 50px;
    margin-bottom: 30px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
	}
	
	#progressbar li {
    list-style-type: none;
    color: rgb(138, 149, 219);
    text-transform: uppercase;
    font-size: 9px;
    width: 25%;
    float: left;
    position: relative;
    letter-spacing: 1px;
    text-align: center;
	}
	
	#progressbar li:before {
    content: counter(step);
    counter-increment: step;
    width: 24px;
    height: 24px;
    line-height: 26px;
    display: block;
    font-size: 12px;
    color: #ffffff;
    background: rgb(168, 165, 165);
    border-radius: 25px;
    margin: 0 auto 10px auto;
    text-align: center;
	}
	
	/*progressbar connectors*/
	#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: rgb(200, 199, 199);
    position: absolute;
    left: -50%;
    top: 9px;
    
    opacity: 0.3;
	}
	
	#progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
	}
	
	/*marking active/completed steps green*/
	/*The number of the step and the connector before it = green*/
	#progressbar li.active:before, #progressbar li.active:after {
    background: #ee0979;
    color: white;
	}
	
	.-mt-15
	{
    margin-top: -21px;
    overflow: hidden;
	}
	
	
	/*  */
	
	.line_box {
	display: flex;
	margin-bottom: 40px;
	}
	.text_circle {
	flex: 1;
	text-align: center;
	position: relative;
	}
	.text_circle:after {
	background-color: rgb(226, 224, 224);
	bottom: 1.25em;
	content: "";
	display: block;
	height: 3px;
	position: absolute;
	right: 0;
	width: 50%;
	}
	.stav_projektu .text_circle:after,.stav_projektu .text_circle:before {background-color: rgb(226, 224, 224);;}
	.line_box h4 {
	color: #ee0979;
	font-weight: bold;
	}
	.line_box h4,.line_box p {
	font-size: 12px;
	margin-bottom: 0;
	padding: 0 5px;
	}
	.subline {
	position: absolute;
	right: -25px;
	bottom: -43%;
	}
	.subline:before {
	content: "";
	position: absolute;
	height: 15px;
	width: 15px;
	bottom: 14px;
	right: 15px;
	background-color: #ee0979;
	border-radius: 50%;
	top: -25px;
	}
	.subline h6 {margin-bottom: 0;}
	.timeline {margin: 40px 0;}
	.text_circle.done:after,.text_circle.done + .text_circle:before,.stav_projektu .text_circle.done:after,.stav_projektu .text_circle.done + .text_circle:before {background-color: #ee0979;}
	.text_circle.sub:after {background-color: #ee0979;}
	.text_circle:not(:first-child):before {
	bottom: 1.25em;
	content: "";
	display: block;
	height: 3px;
	position: absolute;
	left: 0;
	width: 50%;
	background-color: rgb(226, 224, 224);;
	}
	.stav_projektu .text_circle:not(:first-child):before {background-color: rgb(226, 224, 224);;}
	.text_circle:last-child:after {width: 0;}
	.circle {height: 100%;}
	.tvar {
	height: 40px;
	width: 40px;
	border: 2px solid #ee0979;
	display: flex;
	position: relative;
	border-radius: 100%;
	top: -43px;
	margin: 3px auto;
	background-color: #fff;
	}
	.tvar span {
	margin: 25% auto;
	height: 20px;
	width: 20px;
	background-color: #ee0979;
	border-radius: 100%;
	color: #fff;
	}
	.stav_projektu .tvar {border: 2px solid rgb(226, 224, 224);;}
	.stav_projektu .done .tvar,.stav_projektu .sub .tvar {border: 2px solid #ee0979;}
	.subline h6 {color: #ee0979;}
	.timeline .card-header:hover {
	background-color: #ececec;
	cursor: pointer;
	}
	/* iPhone X ----------------------------------- */
	@media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
    .subline:before {top: -43px;}
	}
	@media only screen and (device-width : 812px) and (device-height : 375px) and (orientation : landscape) and (-webkit-device-pixel-ratio : 3) {
    .subline:before {top: -31px;}
	}
	/* iPad portrait ----------------------------------- */
	@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .subline:before {top: -29px;}
	}
	/* mobile width till 767px ----------------------------------- */
	@media (max-width: 767px){
    .subline:before {top: -30px;}
	}
	/* Portrait iPad Pro */
	@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .subline:before {top: -23px;}
	}
	/* mobile width till 480px ----------------------------------- */
	@media (max-width: 480px){
    .subline:before {top: -43px;}
	}
	
	.status_color
	{
    background: #770bd1b3;
    color: white;
	}
	.event_color
	{
    background: #f10505b3;
    color: white;
	}
	
	.card .card-footer{
    background-color: #ffffff;
    border-top:2px solid #770bd1b3;
    color: black;
	}
	
	/*Card Footer Fixed*/
	@supports (box-shadow: 2px 2px 2px black){
	.cart-panel-foo-fix{
    position: fixed;
    z-index: 9;
	}
	}
	
	
	.table-recipt
	thead th,
	tfoot tr {
	position: sticky;
	background: rgb(255, 255, 255)
	}
	
	.table-recipt thead th {
	top: 0
	}
	
	.table-recipt tfoot tr {
	bottom: 0
	}
	
	/* .table-recipt
	th,
	td {
	font-size: 12px;
	text-align: center
	} */
	
	
	/*give some space between thead and tfoot*/
	.table-recipt 
	tbody tr:first-of-type td {
	padding-top:  10px;
	}
	.table-recipt 
	tbody tr:last-of-type td {
	padding-bottom: 10px;
	}
	.text-venuecolor {
    color: #DD1BFB;
    text-transform: capitalize;
	}
	
	/* Progress */
	
	
	.icon {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	fill: none;
	}
	
	.hidden {
	display: none;
	}
	
	
	.step {
	flex-grow: 1;
	position: relative;
	}
	
	.step-progress {
	width: 100%;
	height: 0.25em;
	background: red;
	}
	.icon-wrapper {
	text-align: center;
	display: inline-block;
	}
	
	.step.done .step-progress:after {
	position: absolute;
	content: '';
	height: 0.25em;
	width: 0;
	background-color: yellow;
	animation: growLine 1s linear forwards;
	}
	
	.icon-checkmark {
	position: absolute;
	top: -0.55em;
	left: -0.125em;
	border: 0.125em solid red;
	background: blue;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	padding: 0.125em;
	border-radius: 50%;
	transition: all 0.25s linear;
	}
	.step.done .icon-checkmark {
	background: rebeccapurple;
	border-color: red;
	}
	
	.icon-checkmark .path1 {
	stroke: greenyellow; 
	stroke-width: 4;
	stroke-linecap: square;
	stroke-dasharray: 1000;
	stroke-dashoffset: 1000;
	fill: red;
	}
	.step.done .icon-checkmark .path1 {
	animation: dash 5s linear forwards;
	stroke: yellow;
	}
	
	.step-text {
	position: relative;
	margin-left: -50%;
	letter-spacing: 1px;
	font-weight: bold;
	color: green;
	margin-top: 0;
	opacity: 0;
	}
	.step.done .step-text {
	color: red;
	animation: dropText 0.5s linear forwards;
	}
	
	@keyframes dash {
	to {
    stroke-dashoffset: 0;
	}
	}
	
	@keyframes growLine {
	to {
    width: 100%;
	}
	}
	
	@keyframes dropText {
	to {
    padding-top: 1em;
    opacity: 1;
	}
	}
	.border-b-2
	{
	border:1px solid white !important;
	}
	.list-group-items
	{
	background-color:unset !important;
	}
	/* tooltip */
	
	
	/* progress*/
	
	
	
	@keyframes animate-stripes {
	0% { background-position: 0 0; }
	100% { background-position: 60px 0; }
	}
	
	/* Youtube Link */
	#yt_link
	{
	position: absolute;
	right: 0;
	left: 0;
	bottom: -200px;
	display: block;
	width: 160px;
	text-align: center;
	color: #fff;
	font-size: 15px;
	text-decoration: none;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	padding: 10px;
	margin: 0 auto;
	background-color: red;
	border-radius: 2px;
	animation: showYtLink 1.5s ease 3s forwards;
	}
	
	@keyframes showYtLink
	{
	0%{ bottom: -200px; }
	100%{ bottom: 20px; }
	}
	.start_box
	{
	box-shadow: red 3px 6px 11px 3px;
	/* animation: animate 40s linear infinite;  */
	}
	.Closing
	{
	box-shadow: rgb(244, 62, 202) 3px 6px 11px 3px;
	/* animation: animate 40s linear infinite;  */
	}
	.animateCss
	{
	color:red;
	font-size:14px;
	animation: animate 3.5s linear infinite; 
	}
	@keyframes animate{ 
	0%{ 
    opacity: 0.3; 
	color:rgb(222, 9, 214);
	} 
	50%{ 
    opacity: 0.7; 
	color:red;
	} 
	100%{ 
    opacity: 0.3; 
	color:rgb(222, 9, 214);
	} 
	} 
	
	
	
	.progressBarContainer {
	position: relative;
	width:120%;
	}
	
	#progressBar {
	background: white;
	display: flex;
	position: relative;
	height: 40px;
	/*  box-shadow: 4px 4px 1px #F47D61; */
	}
	
	#progressLevel {
	height: 100%;
	transition: all 0.1s linear 0s;
	margin-bottom: 40px;
	background: #30e177;
	position: absolute;
	opacity: 0.5;
	margin-top: 17px;
	}
	
	.fullbar {
	border-radius: 1.3125rem !important;
	}
	
	#percentage {
	display: flex;
	margin-left: 0%;
	transition: all 0.1s linear 0s;
	position: absolute;
	z-index: 1;
	top: 13px;
	left: 0;
	}
	
	
	
	ul.cp-steps{
	list-style: none;
	display:flex;
	padding-left:0;
	width:100%
	}
	ul.cp-steps li.wp-90{
	width:90%;
	color:#fff;
	font-size:12px;
	text-transform:uppercase;
	height:34px;
	line-height:38px;
	position:relative;
	text-align:center;
	margin-top: 20px;
	}
	ul.cp-steps li.wp-10{
    width: 10%;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    height: 34px;
    line-height: 38px;
    position: relative;
    text-align: center;
    margin-top: 20px;
	}
	ul.cp-steps li.wp-20{
    width: 10%;
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    height: 34px;
    line-height: 38px;
    position: relative;
    text-align: center;
    margin-top: 20px;
	}
	
	ul.cp-steps li:nth-child(1){background:green;}
	ul.cp-steps li:nth-child(2){background:red;}
	ul.cp-steps li:nth-child(3){background:green;}
	ul.cp-steps li:nth-child(1):after{
	content:'';
	position:absolute;
	border-top:19px solid transparent;
	border-bottom:19px solid transparent;
	border-left:19px solid green;
	right:-18px;
	top:0;
	}
	ul.cp-steps li:nth-child(2):after{
	content:'';
	position:absolute;
	border-top:19px solid transparent;
	border-bottom:19px solid transparent;
	border-left:19px solid red;
	right:-18px;
	top:0;
	}
	@media only screen and (max-width: 767px){
	ul.cp-steps li{
    font-size:15px;
	}
	}
	@media only screen and (max-width: 350px){
	ul.cp-steps li{
    font-size:13px;
	}
	}
	
	.border-b
	{
	border-bottom: 5px solid #770bd1b3;
	}
	.border-b_
	{
	border-bottom: 2px solid #770bd1b3;
	}
	.mdi:before, .mdi-set
	{
	transform: none !important;
	
	}
	.fs-20
	{
	font-size: 20px;
	}
	.border-l-5 
	{
	border-left: 2px solid #770bd1b3;
	position: absolute;
	width: 123px;
	}
	.h-10
	{
	height: 29px;
	}
	.wprogress-80
	{
	left: 80%;
	}
	.wprogress-90
	{
	left: 90%;
	}
	.m-top-30
	{
	margin-top: 20%;
    margin-left: -261px;
    color: #770bd1b3;
	
	}
	.m-top-31
	{
	margin-top: 20%;
    margin-left: 0%;
	color:#770bd1b3;
	width:150px;
	}
	
	.m-top-32 {
	margin-top: 20%;
    margin-left: -205%;
    color: #770bd1b3;
	}
	.m-top-33 {
	margin-top: 20%;
    margin-left: -140%;
    color: #770bd1b3;
	}
	.m-top-0
	{
    margin-top: 4%;
    margin-left: 0%;
	color:#770bd1b3;
	}
	
	.triangle_down1 {
	position: absolute;
    top: 19px;
    content: "";
    display: inline-block;
    width: 11px;
    height: 11px;
    border-right: 0.2em solid #770bd1b3;
    border-top: 0.2em solid #770bd1b3;
    transform: rotate(135deg);
    margin-right: 0.5em;
    margin-left: -38px;
	}
	.w-40
	{
	width:40%;
	}
	
	
	.progress-title{
	font-size: 16px;
	color:#011627;
	margin:0px 0 30px !important;
	font-weight: 700;
	}
	.progress{
	background-color:#f7f7f7;
	height:15px;
	overflow: visible !important;
	position: relative;
	margin-bottom: 0;
	border-radius:0px !important;
	box-shadow: none;
	}
	.progress .progress-bars{
	animation: animate-cycle 2s;
	box-shadow: none;
	position: relative;
	}
	.progress .progress-bars:before{
	content: "";
	position: absolute;
	width: 100%;
	top: 6px;
	left: 0;
	border: 2px dashed #fff;
	}
	.progress .progress-bars:after{
	content: ' \2708';
	position:absolute;
	right: -12px;
    top: 9px;
    font-size: 39px;
	color: #000;
	}
	@-webkit-keyframes animate-cycle {
	0% { width: 0%; }
	}
	
	@keyframes animate-cycle {
	0% { width: 0%; }
	}
	
	
	/* Notification  */
	
	.bell{
	display:block;
	color: #eb5e16;
	-webkit-animation: ring 4s .7s ease-in-out infinite;
	-webkit-transform-origin: 50% 4px;
	-moz-animation: ring 4s .7s ease-in-out infinite;
	-moz-transform-origin: 50% 4px;
	animation: ring 4s .7s ease-in-out infinite;
	transform-origin: 50% 4px;
	}
	
	@-webkit-keyframes ring {
	0% { -webkit-transform: rotateZ(0); }
	1% { -webkit-transform: rotateZ(30deg); }
	3% { -webkit-transform: rotateZ(-28deg); }
	5% { -webkit-transform: rotateZ(34deg); }
	7% { -webkit-transform: rotateZ(-32deg); }
	9% { -webkit-transform: rotateZ(30deg); }
	11% { -webkit-transform: rotateZ(-28deg); }
	13% { -webkit-transform: rotateZ(26deg); }
	15% { -webkit-transform: rotateZ(-24deg); }
	17% { -webkit-transform: rotateZ(22deg); }
	19% { -webkit-transform: rotateZ(-20deg); }
	21% { -webkit-transform: rotateZ(18deg); }
	23% { -webkit-transform: rotateZ(-16deg); }
	25% { -webkit-transform: rotateZ(14deg); }
	27% { -webkit-transform: rotateZ(-12deg); }
	29% { -webkit-transform: rotateZ(10deg); }
	31% { -webkit-transform: rotateZ(-8deg); }
	33% { -webkit-transform: rotateZ(6deg); }
	35% { -webkit-transform: rotateZ(-4deg); }
	37% { -webkit-transform: rotateZ(2deg); }
	39% { -webkit-transform: rotateZ(-1deg); }
	41% { -webkit-transform: rotateZ(1deg); }
	
	43% { -webkit-transform: rotateZ(0); }
	100% { -webkit-transform: rotateZ(0); }
	}
	
	@-moz-keyframes ring {
	0% { -moz-transform: rotate(0); }
	1% { -moz-transform: rotate(30deg); }
	3% { -moz-transform: rotate(-28deg); }
	5% { -moz-transform: rotate(34deg); }
	7% { -moz-transform: rotate(-32deg); }
	9% { -moz-transform: rotate(30deg); }
	11% { -moz-transform: rotate(-28deg); }
	13% { -moz-transform: rotate(26deg); }
	15% { -moz-transform: rotate(-24deg); }
	17% { -moz-transform: rotate(22deg); }
	19% { -moz-transform: rotate(-20deg); }
	21% { -moz-transform: rotate(18deg); }
	23% { -moz-transform: rotate(-16deg); }
	25% { -moz-transform: rotate(14deg); }
	27% { -moz-transform: rotate(-12deg); }
	29% { -moz-transform: rotate(10deg); }
	31% { -moz-transform: rotate(-8deg); }
	33% { -moz-transform: rotate(6deg); }
	35% { -moz-transform: rotate(-4deg); }
	37% { -moz-transform: rotate(2deg); }
	39% { -moz-transform: rotate(-1deg); }
	41% { -moz-transform: rotate(1deg); }
	
	43% { -moz-transform: rotate(0); }
	100% { -moz-transform: rotate(0); }
	}
	
	@keyframes ring {
	0% { transform: rotate(0); }
	1% { transform: rotate(30deg); }
	3% { transform: rotate(-28deg); }
	5% { transform: rotate(34deg); }
	7% { transform: rotate(-32deg); }
	9% { transform: rotate(30deg); }
	11% { transform: rotate(-28deg); }
	13% { transform: rotate(26deg); }
	15% { transform: rotate(-24deg); }
	17% { transform: rotate(22deg); }
	19% { transform: rotate(-20deg); }
	21% { transform: rotate(18deg); }
	23% { transform: rotate(-16deg); }
	25% { transform: rotate(14deg); }
	27% { transform: rotate(-12deg); }
	29% { transform: rotate(10deg); }
	31% { transform: rotate(-8deg); }
	33% { transform: rotate(6deg); }
	35% { transform: rotate(-4deg); }
	37% { transform: rotate(2deg); }
	39% { transform: rotate(-1deg); }
	41% { transform: rotate(1deg); }
	
	43% { transform: rotate(0); }
	100% { transform: rotate(0); }
	}
	.disable_task
	{
	background:rgb(240, 239, 239);
	pointer-events: none;
	padding:10px;
	}
	.center_task
	{
	margin-left: 18px;
	}
	.disable_button
	{
	background:rgb(240, 239, 239);
	pointer-events: none;
	cursor: not-allowed;
	}
	.table-top-set
	{
	vertical-align: top !important;
	}

.panel-table
{
	border: 1px solid gray;
    padding: 14px;
}
.panel-heading
{
	border-bottom: 1px solid gray;
    padding: 4px;
}

.side-container--sticky  {
      
	position: fixed;
	top: 75px;
	width: calc(22%);
	overflow: hidden;
  overflow-y: scroll;
  
	}
	
</style>